'use strict';

import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import '../css/main.css';
import BudgetResultScenarioCellRenderer from '../cellrenderers/BudgetResultScenarioCellRenderer';
import BudgetResultStatusCellEditor from '../celleditors/BudgetResultStatusCellEditor';
import MinCellRenderer from '../cellrenderers/MinCellRenderer'; 
import MaxCellRenderer from '../cellrenderers/MaxCellRenderer'; 
import EnableCampaignGroup from '../cellrenderers/EnableCampaignGroup'; 
import MinMaxCustomTooltip from '../tooltips/MinMaxCustomTooltip';
import { Switch } from '@material-ui/core';
import clonedeep from 'lodash/cloneDeep';

export class MainGridComponent extends Component {
  constructor(props) {
    super(props);
    window.addEventListener('resize', this.resizeColumns)
    this.state = {
      show: false,
      isChecked: false,
      constraints: "Perc. Constraints",
      errorMsg: "",
      redirect: "",
      accountId: "",
      apiData: null,
      selectedBudgetOutput: null,
      selectedBudgetOutputId: null,
      gridApi: null,
      gridColumnApi: null,
      columnDefs: [],
      isConvertToWideActive: false,
      timeSeriesParams : {timeSeriesCampaignGrouping: "All", timeSeriesSegment: "Monthly"},
      minPercent: '.05',
      maxPercent: '.8',
      tooltipShowDelay: 0,
      tooltipHideDelay: 2000,
      // isFullWidthCell: function (rowNode) {
      //   return rowNode.data.fullWidth;
      // },
      frameworkComponents: {
        budgetResultScenarioCellRenderer: BudgetResultScenarioCellRenderer,
        minCellRenderer: MinCellRenderer,
        maxCellRenderer: MaxCellRenderer,
        budgetResultStatusCellEditor: BudgetResultStatusCellEditor,
        minMaxCustomTooltip: MinMaxCustomTooltip,
        enableCampaignGroup: EnableCampaignGroup
      },
      getRowHeight: function (params) {
        var isBodyRow = params.node.rowPinned === undefined;
        var isFullWidth = params.node.data.fullWidth;
        if (isBodyRow && isFullWidth) {
          return 40;
        }
      },
    };
  }

  // fullWidthCellRenderer = (params) => {
  //   switch (this.props.pagetype){
  //     case 'BudgetPlanner':
  //         return '<div class="grid-new-button"><span class="grid-new-button-text">+ New Campaign Grouping</span><div>';
  //     case 'BudgetResults':
  //         return '<div class="grid-new-button"><span class="grid-new-button-text">+ New Plan</span><div>';
  //   }
  // }

  componentDidMount = async () =>
  {
    await this.fetchData()
    if (this.props.gridtype === "recentBudgetPlans")
      {
        var colDefs = await this.getColumnDefs(this.props.apiData)
        this.setState({columnDefs: colDefs})
      }
  }

  componentDidUpdate = async (prevProps, prevState) =>
  {
    if ((this.props.accountId !== prevProps.accountId) || (this.props.selectedBudgetOutputId !== prevProps.selectedBudgetOutputId))
    {
      await this.fetchData()
    }

    if (this.props.gridtype === "recentBudgetPlans" && this.props.isBudgetRecommendation)
    {
      await this.fetchData()
    }

    // if(JSON.stringify(prevProps.apiData) !== JSON.stringify(this.props.apiData) && this.props.gridtype === "recentBudgetPlans")
    // {
      
    //   var colDefs = await this.getColumnDefs(this.props.apiData)
    //   this.setState({columnDefs: colDefs})

    // }
    
    //side menu swap conditionals for later use
    //((this.props.accountId !== prevProps.accountId) || (this.props.selectedBudgetOutputId !== prevProps.selectedBudgetOutputId)) 
    if (this.props.gridtype === "timeSeriesGrid" && ((JSON.stringify(prevProps.timeSeriesCost) !== JSON.stringify(this.props.timeSeriesCost) && this.props.timeSeriesCost.length > 0) || prevState.isConvertToWideActive !== this.state.isConvertToWideActive || JSON.stringify(this.props.timeSeriesParams) !== JSON.stringify(this.state.timeSeriesParams)))
    {
      var isConvertToWideActive;
      if ((this.props.accountId !== prevProps.accountId) || (this.props.selectedBudgetOutputId !== prevProps.selectedBudgetOutputId))
        isConvertToWideActive = false;
      else
        isConvertToWideActive = this.state.isConvertToWideActive;
      var json = await this.props.fetchData(isConvertToWideActive)
      var colDefs = await this.getColumnDefs(json)
      this.setState({
        apiData: json,
        accountId: clonedeep(this.props.accountId),
        selectedBudgetOutputId: clonedeep(this.props.selectedBudgetOutputId),
        isConvertToWideActive : isConvertToWideActive,
        timeSeriesParams: clonedeep(this.props.timeSeriesParams),
        columnDefs: colDefs
        });
    }
  }

 
  toggleSwitch = (e) => {
    var isChecked = !this.state.isChecked
    var constraints = isChecked ? "Dollar Constraints" : "Perc. Constraints"
    if (isChecked)
    {
      this.state.gridColumnApi.setColumnVisible('max_spend', true);
      this.state.gridColumnApi.setColumnVisible('min_spend', true);
      this.state.gridColumnApi.setColumnVisible('max_percent', false);
      this.state.gridColumnApi.setColumnVisible('min_percent', false);
    }
    else
    {
      this.state.gridColumnApi.setColumnVisible('max_spend', false);
      this.state.gridColumnApi.setColumnVisible('min_spend', false);
      this.state.gridColumnApi.setColumnVisible('max_percent', true);
      this.state.gridColumnApi.setColumnVisible('min_percent', true);
    }
    this.setState({isChecked: isChecked, constraints: constraints})
  };


  fetchData = async() =>
  {
      if (this.props.isBudgetRecommendation)
      {
        await this.props.fetchBudgetSweepData();
      }
      else if (this.props.pagetype === "BudgetResults")
      {
        if (this.props.accountId !== "")
        {
          var apiData = await this.props.fetchData();
          var colDefs = await this.getColumnDefs(apiData)
          this.setState({columnDefs: colDefs, apiData:apiData})
        }
      }
      else 
      {
        await this.props.fetchData();
      }
  }

  onGridReady = (params) => {
    params.api.DisableGroup = this.DisableGroup
    params.api.EnableGroup = this.EnableGroup
    this.setState({
      gridApi : params.api,
      gridColumnApi : params.columnApi
    })
  };

  /* pinAddNewButton = () =>
  {
    if (this.props.pagetype === 'BudgetPlanner' || this.props.pagetype === 'BudgetResults')
    {
      if(this.state.gridApi != null)
      {
        var pinnedRow = [];
        var firstElement = Object.create(null);
        pinnedRow.push(firstElement)
        pinnedRow[0].fullWidth = true;
        return pinnedRow;
      }
    }
  }; */

  onAddRowClick = (event) => {
    if (event.data.fullWidth)
    {
      this.state.gridApi.updateRowData({
        add: [{ }], addIndex:0 });
        this.state.gridApi.paginationGoToFirstPage()
        this.state.gridApi.startEditingCell({rowIndex:0, colKey:'A'});
    }
  }

  createData = (count) => {
    var rowData = [];
    if (this.props.apiData != null)
    {
      for (var i = 0; i < this.props.apiData.length; i++)
      {
        rowData.push(this.props.apiData[i])
      }
    }
    return rowData;
  }

  toTitles = (s) => { s = s.replace(/\w\S*/g, function(t) { return t.charAt(0).toUpperCase() + t.substr(1).toLowerCase(); }); if (s === 'Rec Kpi'){s = 'Rec KPI'}; return s;}
  
  getConversionCalculatedHeader = () =>
  {
    //remove this later
    if(this.props.selectedBudgetOutputId === 78)
    {
      return "KPI / Spend"
    }
    switch (this.props.conversionType)
    {
      case "Revenue":
        return "ROAS";
      case "Conversions":
        return "CPA";
      case "Reach":
        return "KPI / Spend";
      case "New Visitors":
        return "NV / Spend";
      case "CPM":
        return "CPM";
      case "Impressions":
        return "CPM"
      case "Views":
        return "CPM"
      case "ThruPlays":
        return "CPM"
      case "Leads":
        return "CPL"
      case "Purchases":
        return "Cost Per. Purchase"
      case "Sales":
        return "Cost Per. Sale";
      case "Landing Page Views":
        return "CPLPV";
      case "Link Clicks":
        return "CPLC";
      case "Free Trials":
        return "CPFT";
      case "Installs":
        return "Cost Per. Install";
      case "Referrals":
        return "Cost Per. Referrals";
      case "Signups":
        return "Cost Per. Signup";
      case "Clicks":
       return "CPC";
      case "Bookings":
       return "Cost Per. Booking";
    }
  }


  getColumnDefs = async (apiData) => {
    if(apiData === null)
    {
      apiData = clonedeep(this.props.apiData)
    }
    var columnDefs = [];
    var colDef = null;
    if (apiData != null)
    {
       var columnApiData = Object.keys(apiData[0]);
       for(var i = 0; i < columnApiData.length; i++)
       {
          colDef = null;
          if(columnApiData[i] !== "min_percent" && columnApiData[i] !== "max_percent"  && columnApiData[i] !== "max_spend" && columnApiData[i] !== "min_spend" && columnApiData[i] !== "warnings" && columnApiData[i] !== "enabled")
          {
            var headerName = ""
            switch (columnApiData[i])
            {
              case "est_roi":
                headerName = this.getConversionCalculatedHeader()
                break;
              case "rec_kpi":
                headerName = this.props.conversionType
                break;
              case "rec_budget":
                headerName = "Rec. Spend"
                break;
              case "adj_spend":
                headerName = "% Adj. Spend"
                break;
              case "TimeSeriesKPI":
                headerName = this.props.conversionType
                break;
              case "TimeSeriesMetricCalculated":
                headerName = this.getConversionCalculatedHeader()
                break;
              default:
                headerName = this.toTitles(columnApiData[i].replaceAll('_', ' '))
            }
            
            colDef = 
            {
              headerName: headerName,
              field: columnApiData[i],
              editable: this.props.gridtype === "recentBudgetPlans" ? false : true,
              valueFormatter: columnApiData[i] === "est_roi" || columnApiData[i] === "TimeSeriesMetricCalculated"  ? /*this.formatROI.bind(this)*/ "": this.formatNumber.bind(this) ,
              width: 150,
              sortable: this.props.gridtype === "recentBudgetPlans" ? true : false,
              comparator: (valueA, valueB) => {
                // Convert the values to numbers for numerical sorting
                const numA = parseFloat(valueA);
                const numB = parseFloat(valueB);
                
                if (numA < numB) return -1;
                if (numA > numB) return 1;
                return 0;
              },
            };
          }
         
          switch(columnApiData[i])
          {
            case 'name':
              colDef.cellRenderer = this.props.gridtype === "recentBudgetPlans" ? '' : 'budgetResultScenarioCellRenderer'
              colDef.accountId = clonedeep(this.props.accountId) 
              colDef.width = this.props.gridtype === "recentBudgetPlans" ? this.props.columnWidth : "750px"
              colDef.tooltipField = columnApiData[i]
              colDef.tooltipComponent = 'customTooltip'
              break
            case 'status':
              colDef.cellEditor = 'budgetResultStatusCellEditor'
              colDef.cellClass = 'status-cell'
              colDef.hide = true
              break
            case 'campaign_grouping_id_id':
              colDef.hide = true
              break
            case 'id':
              colDef.hide = true
              break
            case 'model_frequency':
              colDef.hide = true
              break;
            case 'analysis_type':
              colDef.editable = false
              colDef.valueFormatter = function(params) {
                return params.value === '' ? '' : params.value;
            };
              break;
            case 'active':
              colDef.hide = true
              break;
            case 'upper_quant_bounds_budget':
              colDef.hide = true
              break
          }
          if (colDef !== null)
          {
            columnDefs.push(colDef)
          }
        }
        if (this.props.gridtype === "recentBudgetPlans")
        {
          var colDefMinPerc = 
              {
                headerName: "Min Perc.",
                field: "min_percent",
                editable:true,
                hide: this.state.isChecked,
                cellRenderer: "minCellRenderer",
                tooltipComponent: "minMaxCustomTooltip",
                tooltipField: 'min_percent',
                sortable: true,
                comparator: (valueA, valueB) => {
                  // Convert the values to numbers for numerical sorting
                  const numA = parseFloat(valueA);
                  const numB = parseFloat(valueB);
          
                  if (numA < numB) return -1;
                  if (numA > numB) return 1;
                  return 0;
                },
                valueFormatter: this.percentageFormatter.bind(this),
                onCellValueChanged: this.minMaxChange.bind(),
              };
          var colDefMaxPerc = 
              {
                headerName: "Max Perc.",
                field: "max_percent",
                editable:true,
                hide: this.state.isChecked,
                cellRenderer: "maxCellRenderer",
                tooltipComponent: "minMaxCustomTooltip",
                tooltipField: 'max_percent',
                sortable: true,
                comparator: (valueA, valueB) => {
                  // Convert the values to numbers for numerical sorting
                  const numA = parseFloat(valueA);
                  const numB = parseFloat(valueB);
          
                  if (numA < numB) return -1;
                  if (numA > numB) return 1;
                  return 0;
                },
                valueFormatter: this.percentageFormatter.bind(this),
                onCellValueChanged: this.minMaxChange.bind()
              };
              var colDefMinSpend = 
              {
                headerName: "Min Spend",
                field: "min_spend",
                editable:true,
                hide: !this.state.isChecked,
                cellRenderer: "minCellRenderer",
                tooltipComponent: "minMaxCustomTooltip",
                tooltipField: 'min_spend',
                sortable: true,
                comparator: (valueA, valueB) => {
                  // Convert the values to numbers for numerical sorting
                  const numA = parseFloat(valueA);
                  const numB = parseFloat(valueB);
          
                  if (numA < numB) return -1;
                  if (numA > numB) return 1;
                  return 0;
                },
                onCellValueChanged: this.minMaxSpendChange.bind() 
              };
          var colDefMaxSpend = 
              {
                headerName: "Max Spend",
                field: "max_spend",
                hide: !this.state.isChecked,
                editable:true,
                cellRenderer: "maxCellRenderer",
                tooltipComponent: "minMaxCustomTooltip",
                tooltipField: 'max_spend',
                sortable: true,
                comparator: (valueA, valueB) => {
                  // Convert the values to numbers for numerical sorting
                  const numA = parseFloat(valueA);
                  const numB = parseFloat(valueB);
          
                  if (numA < numB) return -1;
                  if (numA > numB) return 1;
                  return 0;
                },
                onCellValueChanged: this.minMaxSpendChange.bind()
              };
          var colDefWarnings = 
              {
                headerName: "Warnings",
                field: "warnings",
                editable:false,
                valueFormatter: this.arrayTextFormatter.bind(this),
                hide:true
              };
        var colDefEnabled = {
            headerName: 'Enabled',
            field: 'enabled',
            cellRenderer: 'enableCampaignGroup',
            editable: false,
            width: 100,
            suppressSizeToFit: true,
            cellClass: 'enable-cell',
          };
          columnDefs.push(colDefMinPerc);
          columnDefs.push(colDefMaxPerc);
          columnDefs.push(colDefMinSpend);
          columnDefs.push(colDefMaxSpend);
          columnDefs.push(colDefWarnings);
          columnDefs.push(colDefEnabled);
        }
      }
   
    //custom colums added on front end for min/max perc.
    return columnDefs;
  }

  minMaxChange = async () =>
  {
    for (var i = 0; i < this.state.gridApi.getDisplayedRowCount(); i++)
    {
      if ((parseInt(this.state.gridApi.getRowNode(i).data.min_percent) > parseInt(this.state.gridApi.getRowNode(i).data.max_percent)) || parseInt((this.state.gridApi.getRowNode(i).data.max_percent) < parseInt(this.state.gridApi.getRowNode(i).data.min_percent)))
      {
          var error = this.state.gridApi.getRowNode(i).data.min_percent > this.state.gridApi.getRowNode(i).data.max_percent ? "Min Percent cannot be greater than Max Percent" : "Max Percent cannot be less than Min Percent"
          this.setState({show:true, errorMsg:error})
          return 
      }
    }
    await this.props.setBudgetOptimizationParams(this.state.gridApi, this.state.isChecked, null, null)
    await this.props.refreshBudgetRecommendation()
    //await this.fetchData()
    
  }

  minMaxSpendChange = async () =>
  {
    for (var i = 0; i < this.state.gridApi.getDisplayedRowCount(); i++)
    {
      if ((parseInt(this.state.gridApi.getRowNode(i).data.min_spend) > parseInt(this.state.gridApi.getRowNode(i).data.max_spend)) || parseInt((this.state.gridApi.getRowNode(i).data.max_spend) < parseInt(this.state.gridApi.getRowNode(i).data.min_spend)))
      {
          var error = this.state.gridApi.getRowNode(i).data.min_spend > this.state.gridApi.getRowNode(i).data.max_spend ? "Min Spend cannot be greater than Max Spend" : "Max Spend cannot be less than Min Spend"
          this.setState({show:true, errorMsg:error})
          return 
      }
    }
    await this.props.setBudgetOptimizationParams(this.state.gridApi, null, null)
    await this.props.refreshBudgetRecommendation()
    //await this.fetchData()
    
  }

  onFirstDataRendered = async () =>
  {
    if (this.state.gridApi !== null && this.props.gridtype === "recentBudgetPlans")
    {
      //this.state.gridApi.sizeColumnsToFit({defaultMinWidth: 150});
      await this.props.setBudgetOptimizationParams(this.state.gridApi, this.state.isChecked, null, null);
    }
  }

  percentageFormatter = (params) =>
  {
    if (!isNaN(params.value))
      return params.value*100 + "%";
  }

  arrayTextFormatter = (params) =>
  {
    if (params.length > 1)
    {
      return JSON.stringify(params)
    }
  }

  formatNumber = (params) => {
    if (!isNaN(params.value))
    {
      return Math.floor(params.value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
  }

  formatROI = (params) => {
    return Math.ceil(params.value * 100) / 100
  }

  exportToExcel = () =>
  {
    var fileName = ""
    switch(this.props.gridtype)
    {
      case "timeSeriesGrid":
        fileName = "timeseries_"
        break;
      case "recentBudgetPlans":
        fileName = "recentBudgetPlans_"
        break;
      default:
        fileName = "grid_export_"

    }
    var params = {fileName: fileName + Date.now()}
    this.state.gridApi.exportDataAsCsv(params);
  }

  convertToWide = (e) =>
  {
      if (!this.state.isConvertToWideActive)
          this.setState({isConvertToWideActive: true})
      else
          this.setState({isConvertToWideActive: false})
  }

  handleClose = () =>
  {
    this.setState({show: false, errorMsg: ""})
  }

  DisableGroup = async (groupId, name, index, maxSpend) => {
    await this.props.setBudgetOptimizationParams(this.state.gridApi, this.state.isChecked, groupId, 'disable', name, index, maxSpend)
    await this.props.refreshBudgetRecommendation()
    //await this.fetchData()
  };

  EnableGroup = async (groupId, name, index, maxSpend) => {
    await this.props.setBudgetOptimizationParams(this.state.gridApi, this.state.isChecked, groupId, 'enable', name, index, maxSpend)
    await this.props.refreshBudgetRecommendation()
    //await this.fetchData()
  };

  render() {
    return (
          <><div
            id="myGrid"
            style={{
              height: '100%',
              width: '100%',
            }}
            className="ag-theme-material"
          >{this.props.gridtype === 'timeSeriesGrid' ?
            (<div style={{float:'right'}}><b>Convert to wide</b><Switch checked={this.state.isConvertToWideActive} onChange={this.convertToWide} color='primary'></Switch><button onClick={this.exportToExcel} className='btn btn-primary'>Export</button></div>)
              :
            ("")}
            {this.props.gridtype === 'recentBudgetPlans' ? <div style={{float:"right"}}><b>{this.state.constraints}</b><Switch checked={this.state.isChecked} color="primary" onClick={this.toggleSwitch}></Switch>&nbsp;&nbsp;<a className='btn btn-primary' onClick={this.exportToExcel}>Export</a></div> : "" }
            
            <AgGridReact
              rowData={this.props.gridtype === "recentBudgetPlans" ? this.props.apiData : this.state.apiData}
              onGridReady={this.onGridReady} 
              onFirstDataRendered={this.onFirstDataRendered}
              //pinnedTopRowData={this.pinAddNewButton()}
              columnDefs={this.state.columnDefs}
              frameworkComponents={this.state.frameworkComponents}
              //isFullWidthCell={this.state.isFullWidthCell}
              //fullWidthCellRenderer={this.fullWidthCellRenderer}
              getRowHeight={this.state.getRowHeight}
              pagination={this.props.pagetype === "BudgetResultsOutput" ? false : true}
              paginationPageSize={20}
              rowSelection={'single'}
              onRowClicked={this.onAddRowClick.bind(this)}
              tooltipShowDelay={this.state.tooltipShowDelay}
              tooltipHideDelay={this.state.tooltipHideDelay}
              editType= 'fullRow'/>
          </div>
          <Modal show={this.state.show}>
            <Modal.Header>
              <Modal.Title>Constraint Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.errorMsg}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={this.handleClose}>
                  Close
                </Button>
              </Modal.Footer>
          </Modal>
          </>
    );
  }
}

export default MainGridComponent