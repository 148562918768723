import React, { Component } from 'react';
import '../css/main.css';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";

export default class BudgetResultStatusCellEditor extends Component {
    constructor(props) {
        super(props);

        this.state =
        {
          status : ""
        }
      }
    
    componentDidMount = () =>
    {
      this.setState({status: this.props.data.status})
    }

    getValue = () =>
    {
      return this.state.status
    }

    change = (event) =>
    {
      this.setState({status : event.target.value})
    }

   render() {
        return(
          <select className="status-cell-dropdown" onChange={this.change} value={this.state.status}>
            <option value="None">None</option>
            <option value="In-Progress">In-Progress</option>
            <option value="Complete">Complete</option>
          </select>
        )
    }
}