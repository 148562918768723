import React from "react";
import AnimationRevealPage from "./../helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "./../components/headers/light.js";
import Footer from "./../components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "./../components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "./../components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "./../components/cards/ProfileThreeColGrid.js";
import GlobalStyles from '../styles/GlobalStyles';
import SupportIconImage from "./../images/support-icon.svg";
import ShieldIconImage from "./../images/shield-icon.svg";
import CustomerLoveIconImage from "./../images/simple-icon.svg";
import mobile from "./../images/caliper-mobile-licensed.png";
import marginal from "./../images/marginal-efficiency.png";
import laptop from "./../images/caliper-laptop-licensed.png";
import finalChart from "./../images/finalChart.png";
import strength from "./../images/caliper-strengths.png";

const Subheading = tw.span`uppercase tracking-wider text-sm text-custom-100`;
export default ({setPage = setPage}) => {
  const handlePageChange = (pageType) =>
  {
    setPage(pageType)
  }
  return (
    <><GlobalStyles />
    <AnimationRevealPage>
      <Header handlePageChange={handlePageChange}  />
      <MainFeature1
        subheading={<Subheading>WHY</Subheading>}
        heading="Enduring challenges of media measurement provide the impetus for next generation solutions"
        imageSrc={laptop}
        description="Long lead times, extensive data requirements, difficult to trust ROI estimates, and high costs inspired the development of Caliper, which does not claim to be a cure-all, but rather addresses a few of the most persistent drawbacks of marketing measurement."
      />
      <MainFeature1
        subheading={<Subheading>OUR STRENGTHS</Subheading>}
        heading="Benefit from rapid turnaround, reasonable costs, and incorporation of indirect effects"
        imageSrc={strength}
        textOnLeft={false}
        description="Focus on digital channels enables Caliper to source data directly through APIs, significantly reducing time to insights. This balance of automation and human intervention in turn decreases costs to generate analyses."
      />
      <MainFeature1
        subheading={<Subheading>HOW</Subheading>}
        heading="Generate optimization scenarios on-demand through a user-friendly UI"
        imageSrc={mobile}
        description="Budgets, planning horizons, and business constraints change continuously. Fortunately, Caliper's UI allows users to run endless scenarios with ease."
      />
      <MainFeature1
        subheading={<Subheading>OUR RESULT</Subheading>}
        heading="94% avg. prediction accuracy, giving clients confidence in scenario forecasts"
        description="Clients increasing or decreasing investment can confidently adjust budget knowing Caliper's KPI estimates are reliable due to a robust ensemble modeling framework."
        imageSrc={finalChart}
        textOnLeft={false}
      />
      <Footer />
    </AnimationRevealPage></>
  );
};
