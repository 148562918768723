import React, { Component } from 'react';
import '../css/main.css';

export class Documentation extends Component {

  constructor(props) {
    super(props);
    this.state = {
           
        };
    }

    scrollToSection = (id) => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }

    render() {
        return(
            <>
            <div className='doc-container'>
                <div className="row">
                    <ul className="doc-navi">
                        <li><a className='doc-nav' onClick={() => this.scrollToSection('overview')}>Overview tutorial</a></li>
                        <li><a className='doc-nav' onClick={() => this.scrollToSection('investment-optimization')}>Investment optimization tutorial</a></li>
                        <li><a className='doc-nav' onClick={() => this.scrollToSection('optimal-investment-level')}>Optimal investment level tutorial</a></li>
                        <li><a className='doc-nav' onClick={() => this.scrollToSection('flighting')}>Flighting tutorial</a></li>
                    </ul>
                </div>
                <br />
                <br />
                <div id="overview" className="row">
                    <div  className="col-lg-6">
                        <h3 className='doc-header'>Overview</h3>
                        <p className='doc-body'>Understand the basics of Caliper by watching a within-channel walkthrough. Note, cross-channel analyses use the same modules, therefore this will be applicable to cross-channel users as well. </p> 
                    </div>
                    <div className="col-lg-6">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/TdfznxUNZ68?si=3OyD-qzUOXsZlIJU" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                </div>
                <br />
                <br />
                <div id="investment-optimization" className="row">
                    <div className="col-lg-6">
                        <h3 className='doc-header'>Investment Optimization</h3>
                        <p className='doc-body'>This walkthrough will help users get started with investment optimization, whether using a budget, KPI or KPI efficiency target. Additionally it will let you know how to start small by first addressing the largest allocation opportunities.</p> 
                    </div>
                    <div className="col-lg-6">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/Iy9unpKjaic?si=Hvb0qqk3d5PgehZ3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                </div>
                <br />
                <br />
                <div id="optimal-investment-level" className="row">
                    <div className="col-lg-6">
                        <h3 className='doc-header'>Optimal Investment Level</h3>
                        <p  className='doc-body'>If you are looking to find the optimal investment level for your client this tutorial will walk you through two approaches based on whether or not your client has provided an efficiency target. </p> 
                    </div>
                    <div className="col-lg-6">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/h4pYZD9GQ2M?si=cxI5P7xYiHXma7GP" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                </div>
                <br />
                <br />
                <div id="flighting" className="row">
                    <div className="col-lg-6">
                        <h3 className='doc-header'>Flighting</h3>
                        <p className='doc-body'>Performing annual or quarterly planning and need to know how much investment to recommend by month or week? This tutorial will walk you through how to obtain those answers in a matter of seconds. </p> 
                    </div>
                    <div className="col-lg-6">
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/sTAzbuNWe6U?si=jwoo6OoLtw8zufbe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
            </>
         );
    }
}
