import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "../../helpers/AnimationRevealPage.js";
import GlobalStyles from '../../styles/GlobalStyles';

import Hero from "../../components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "../../components/features/ThreeColWithSideImage.js";
import FeatureStats from "../../components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Footer from "../../components/footers/FiveColumnWithInputForm.js";

const HighlightedText = tw.span`text-custom-100`

export default  ({setPage = setPage}) => {
  const handlePageChange = (pageType) =>
  {
    setPage(pageType)
  }
  return (
    <>
    <GlobalStyles />
    <AnimationRevealPage>
      <Hero handlePageChange={handlePageChange} />
      <FeatureStats/>
      <Features 
        heading={<>Amazing <HighlightedText>Features</HighlightedText></>}
      />
      <Footer />
    </AnimationRevealPage>
    </>
  );
}
