import React, { useRef, useState } from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading, Subheading as SubheadingBase } from "../../components/misc/Headings.js";
import { PrimaryButton as PrimaryButtonBase } from "../../components/misc/Buttons.js";
import EmailIllustrationSrc from "../../images/desktop-contact.png";
import {postApiData, getApiData} from "../../FetchApi.jsx"
import '../../css/main.css'
import ReCAPTCHA from "react-google-recaptcha";

const Container = tw.div`relative`;
const TwoColumn = tw.div`flex flex-col md:flex-row justify-between max-w-screen-xl mx-auto py-20 md:py-24`;
const Column = tw.div`w-full max-w-md mx-auto md:max-w-none md:mx-0`;
const ImageColumn = tw(Column)`md:w-5/12 flex-shrink-0 h-80 md:h-auto`;
const TextColumn = styled(Column)(props => [
  tw`md:w-7/12 mt-16 md:mt-0`,
  props.textOnLeft ? tw`md:mr-12 lg:mr-16 md:order-first` : tw`md:ml-12 lg:ml-16 md:order-last`
]);

const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`rounded bg-contain bg-no-repeat bg-center h-full`,
]);
const TextContent = tw.div`lg:py-8 text-center md:text-left`;

const Subheading = tw(SubheadingBase)`text-center md:text-left`;
const Heading = tw(SectionHeading)`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
const Description = tw.p`mt-4 text-center md:text-left text-sm md:text-base lg:text-lg font-medium leading-relaxed text-secondary-100`

const Form = tw.form`mt-8 md:mt-10 text-sm flex flex-col max-w-sm mx-auto md:mx-0`
const Input = tw.input`mt-6 first:mt-0 border-b-2 py-3 focus:outline-none font-medium transition duration-300 hocus:border-primary-500`
const Textarea = styled(Input).attrs({as: "textarea"})`
  ${tw`h-24`}
`

const SubmitButton = tw(PrimaryButtonBase)`inline-block mt-8`

export default ({
  subheading = "Contact Us",
  heading = <>Feel free to <span tw="text-custom-100">get in touch</span><wbr/></>,
  description = "Reach out if you would like to set up a demo or have any questions. Please indicate in your message if you are interested in cross-channel, within-channel, or both. We will get back to you shortly.",
  submitButtonText = "Send",
  formAction = "/",
  formMethod = "post",
  textOnLeft = true,
}) => {
  // The textOnLeft boolean prop can be used to display either the text on left or right side of the image.
  const [messageSent, setMessageSent] = useState("");
  const formRef = useRef(null);
  const recaptchaRef = useRef(null);
  const sendEmail = async (e) =>
  {
    e.preventDefault()
    const formData = new FormData(formRef.current)
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      alert("Please complete the reCAPTCHA verification.");
      return;
    }
    await getApiData("SEND_CONTACT_EMAIL", [formData.get("email"), formData.get("message"), formData.get("subject"), formData.get("name") ])
    setMessageSent(
      "<div style='color:#147FBB'>Thank you for your message. We will respond to you shortly!</div>"
    );
  }
  return (
    <Container>
      <TwoColumn>
        <ImageColumn>
          <Image imageSrc={EmailIllustrationSrc} />
        </ImageColumn>
        <TextColumn textOnLeft={textOnLeft}>
          <TextContent>
            {subheading && <Subheading>{subheading}</Subheading>}
            <Heading>{heading}</Heading>
            {description && <Description>{description}</Description>}
            <Form ref={formRef} onSubmit={sendEmail}>
              <div dangerouslySetInnerHTML={{ __html: messageSent }} />
              <Input type="email" className="contact-form-input" name="email" placeholder="Your Email Address" />
              <Input type="text" className="contact-form-input" name="name" placeholder="Full Name" />
              <Input type="text" className="contact-form-input" name="subject" placeholder="Subject" />
              <Textarea className="contact-form-input" name="message" placeholder="Your Message Here" />
              <ReCAPTCHA ref={recaptchaRef} sitekey="6Ld8LmInAAAAADKHK4sucsYRrqqpJ_lNKcpjisn9" />
              <SubmitButton className="contact-us-button-homepage" type="submit">{submitButtonText}</SubmitButton>
            </Form>
          </TextContent>
        </TextColumn>
      </TwoColumn>
    </Container>
  );
};
