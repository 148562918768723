import React, { Component } from 'react';
import MainGridComponent from './MainGridComponent';
import '../css/main.css';
import AccountsDropdown from './AccountsDropdown';
import fetchApi from '../FetchApi';

export class BudgetResults extends Component {
  static displayName = BudgetResults.name;

  constructor(props) {
    super(props);
    this.setAccountId = this.setAccountId.bind(this)
    var redirectAccountId = 0
    if (this.props.location.state !== undefined)
        redirectAccountId = parseInt(this.props.location.state.accountId)

    this.state = {
          accountId: "",
          redirectAccountId: redirectAccountId,
          recentBudgetInputs: null,
          apiData: null
        };
    }
    
    fetchData = async() =>
    {
        if (this.state.accountId !== "")
        {
            var apiData = await fetchApi("BUDGET_RESULTS", [this.state.accountId])
            apiData = apiData.filter(x => x.active.toLowerCase() === "active" || x.active === "")
            return apiData;
        }

        return []
    }

    setAccountId = (accountid) =>
    {
      this.setState({accountId: accountid, redirectAccountId: 0 })
    }

    render() {
        return(
        <>
            <div>
                <AccountsDropdown redirectAccountId={this.state.redirectAccountId} setAccountId={this.setAccountId}></AccountsDropdown>
            </div>
            <div className="row">
                <div className="col-md-8 budget-results">
                    <span className="grid-header">Recent Budget Output</span>
                    <MainGridComponent apiData={this.state.apiData} fetchData={this.fetchData} accountId={this.state.accountId} pagetype="BudgetResults"></MainGridComponent>
                </div>
            </div>
        </>
         );
    }
}

export default BudgetResults