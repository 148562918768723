import { useRef, useState, useEffect } from "react";

export default function useInView({ once = true, margin = "-30px 0px 0px 0px" } = {}) {
  const ref = useRef(null);
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const options = {
      rootMargin: margin,
      once: once,
    };

    const observer = new IntersectionObserver(([entry]) => {
      setIsInView(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [margin, once]);

  return [ref, isInView];
}
