import React, { Component } from 'react';
import MainGridComponent from './../MainGridComponent';
import '../../css/main.css';
import GroupedBarChart from './../GroupedBarChart';
import LineChart from './../LineChart';
import BudgetRecommendationParameters from './../BudgetRecommendationParameters';
import fetchApi from '../../FetchApi';

export class CampaignGroupingTemplate extends Component {
  constructor(props) {
        super(props);
        this.state = {
            selectedBudgetOutput: this.props.selectedBudgetOutput,
            accountId: this.props.accountId,
            selectedBudgetOutputId: this.props.selectedBudgetOutputId,
            conversionType: this.props.conversionType,
            modelFrequency: this.props.modelFrequency,
            campaignGrouping: [],
            apiData: null,
            disabledCampaignGroupIds: [],
            disabledRows: [],
            recentBudgetInputs : [{}],
            isBudgetRecommendation: false,
            resetBudget: false,
            selectedDateStart: null,
            selectedDateEnd: null,
            targetValue: '',
            target: "Budget",
            columnWidth: "300px",
            resetBudgetParameters: false,
            recommendedBudget: [],
            apiData: null,
            isOptimized: false,
            isError:false, 
            errorMessage: '',
            timeSeriesDate: [],
            timeSeriesCost: [],
            timeSeriesKPI: [],
            timeSeriesCampaignGrouping: [],
            timeSeriesParams : {timeSeriesCampaignGrouping: "All", timeSeriesSegment: "Weekly (Sunday - Saturday)"},
            budgetOptimizationAPIParams: {maxPercentArray : [], minPercentArray : [], maxSpendArray : [], minSpendArray : [], campaignGroupingArray : [], isDollarConstraint: false, disabledIds: []},
        };
    };

    handleDateChangeStart = (selectedDateStart, selectedDateEnd, targetValue) => {

        if (selectedDateEnd !== null && targetValue !== '')
        {
            this.setState({ selectedDateStart: selectedDateStart.format("MM/DD/YYYY"), isBudgetRecommendation: true });
        }
            
        else
        {
            this.setState({ selectedDateStart: selectedDateStart.format("MM/DD/YYYY"), isBudgetRecommendation: false});
        }
           
      }

    handleDateChangeEnd = (selectedDateStart, selectedDateEnd, targetValue) => {
        if (selectedDateStart !== null && targetValue !== '')
        {
            this.setState({ selectedDateEnd: selectedDateEnd.format("MM/DD/YYYY"), isBudgetRecommendation: true});
        }
            
        else
        {
            this.setState({ selectedDateEnd: selectedDateEnd.format("MM/DD/YYYY"), isBudgetRecommendation: false});
        }
            
      }

    targetValueChange = (selectedDateStart, selectedDateEnd, targetValue) =>
    {
        if (selectedDateStart !== null && selectedDateEnd !== null)
        {
            this.setState({ targetValue: targetValue, isBudgetRecommendation: true });
        }
         
        else
        {
            this.setState({ targetValue: targetValue, isBudgetRecommendation: false });
        }
          
    }

    //fix this later not to set state so many times
    targetDropDownChange = (selectedDateStart, selectedDateEnd, targetValue, target) =>
    {
        this.setState({ target: target, isBudgetRecommendation: false });
        if (selectedDateStart !== null && selectedDateEnd !== null && targetValue !== '')
        {
            this.setState({isBudgetRecommendation: true})
        }
           
    }

    componentDidMount = async() =>
    {
        var apiData =  await fetchApi("BUDGET_RESULTS", [this.state.accountId])

        var recentBudgetInputs = []

        for (var i = 0; i < 3; i++)
        {
            if (i === apiData.length)
                break;
            var recentBudgetInputItem = {id: apiData[i].id, name: apiData[i].name, campaignGroupCount: apiData[i].campaign_group_count, conversionType: apiData[i].conversion_type, modelFrequency: apiData[i].model_frequency}
            recentBudgetInputs.push(recentBudgetInputItem)
        }
        
        this.setState({recentBudgetInputs : recentBudgetInputs})
    }

    componentDidUpdate = async(prevProps, prevState) =>
    {
        if (this.props.selectedBudgetOutputId !== prevProps.selectedBudgetOutputId)
        {
            var apiData =  await fetchApi("BUDGET_RESULTS", [this.state.accountId])

            var recentBudgetInputs = []

            for (var i = 0; i < 3; i++)
            {
                if (i === apiData.length)
                    break;
                var recentBudgetInputItem = {id: apiData[i].id, name: apiData[i].name, campaignGroupCount: apiData[i].campaign_group_count, conversionType: apiData[i].conversion_type, modelFrequency: apiData[i].model_frequency}
                recentBudgetInputs.push(recentBudgetInputItem)
            }
            
            this.setState({modelFrequency: this.props.modelFrequency, conversionType: this.props.conversionType, selectedBudgetOutputId: this.props.selectedBudgetOutputId, selectedBudgetOutput: this.props.selectedBudgetOutput, resetBudgetParameters: true, recommendedBudget : [], isBudgetRecommendation:false, selectedDateStart: null, selectedDateEnd: null, targetValue: '', json: [], isError:false, errorMessage: '', recentBudgetInputs : recentBudgetInputs})
        }
    }

    fetchData = async() =>
    {
        var apiRecommendedBudget = []
        var apiData =  await fetchApi("RECENT_BUDGET_RECOMMENDATIONS", [this.state.selectedBudgetOutputId])
        for (var x = 0; x < apiData.length; x++)
        {
            apiData[x].rec_budget = 0
            apiData[x].avg_hist_spend = 0
            apiData[x].adj_spend = 0
            apiData[x].rec_kpi = 0
            apiData[x].est_roi = 0
            apiData[x].min_spend = "0"
            apiData[x].max_spend = "0"
            apiData[x].enabled = true
            
        }
        //apiData = apiData.map(obj => {return {...obj, max_spend:obj.upper_quant_bounds_budget}})
        for (var i = 0; i < apiData.length; i++)
        {
            apiRecommendedBudget.push({rec_budget: apiData[i].rec_budget, id:apiData[i].id})
        }
        var campaignNames = apiData.map(x => x.name).filter((value, index, self) => self.indexOf(value) === index)
        var columnWidth = this.getColumnWidth(campaignNames);
        this.setState({columnWidth: columnWidth, apiData: apiData, resetBudget:false, resetBudgetParameters:false, recommendedBudget: apiRecommendedBudget})
    }

    getColumnWidth = (campaignNames) =>
    {
        var longestString = Math.max(...(campaignNames.map(el => el.length)));
        if (longestString <= 10)
            return "200px";
        if (longestString <= 20)
            return "250px";
        if (longestString <= 30)
            return "300px";
        if (longestString <= 40)
            return "350px";
        if (longestString <= 60)
            return "400px";
        if (longestString <= 70)
            return "550px";
        if (longestString <= 80)
            return "600px";
        if (longestString <= 90)
            return "650px";
        if (longestString <= 100)
            return "700px";
        if (longestString <= 110)
            return "750px";
    }

    fetchDataTimeSeriesLineChart = async () =>
    {
        var timeSeriesDate = []
        var timeSeriesCost = []
        var timeSeriesKPI = []
        var timeSeriesCampaignGrouping = []
        var apiData =  await fetchApi("TIME_SERIES_OPTIMIZED", [this.state.selectedBudgetOutputId, this.state.accountId, this.state.budgetOptimizationAPIParams.campaignGroupingArray, this.state.selectedDateStart, this.state.selectedDateEnd, this.state.target.toLowerCase(), this.state.targetValue, this.state.budgetOptimizationAPIParams.minPercentArray, this.state.budgetOptimizationAPIParams.maxPercentArray, this.state.budgetOptimizationAPIParams.minSpendArray, this.state.budgetOptimizationAPIParams.maxSpendArray,this.state.budgetOptimizationAPIParams.isDollarConstraint, this.state.budgetOptimizationAPIParams.disabledIds ])
        var timeSeriesCampaignGrouping = apiData.map(e => e.campaign_grouping).sort()
        var unique_campaign_groupings = timeSeriesCampaignGrouping.filter((v, i, a) => a.indexOf(v) === i);
        for (var x = 0; x < unique_campaign_groupings.length; x++)
        {
            var filteredData = apiData.filter(e => {if(e.campaign_grouping === unique_campaign_groupings[x]){return e}})
            timeSeriesCost = [...timeSeriesCost, ...filteredData.map(e => e.spend)]
            timeSeriesKPI = [...timeSeriesKPI, ...filteredData.map(e => e.response)]
            timeSeriesDate =  [...timeSeriesDate, ...filteredData.map(e => e.date)]
        }
       
        this.setState({
            timeSeriesDate : timeSeriesDate,
            timeSeriesCost : timeSeriesCost,
            timeSeriesKPI : timeSeriesKPI,
            timeSeriesCampaignGrouping: timeSeriesCampaignGrouping
        })

    }

    getConversionCalculatedHeader = () =>
    {
        //remove this later
        if(this.state.selectedBudgetOutputId === 78)
        {
            return "KPI / Spend"
        }
      switch (this.state.conversionType)
      {
        case "Revenue":
            return "ROAS";
        case "Conversions":
            return "CPA";
        case "Reach":
            return "KPI / Spend";
        case "New Visitors":
            return "NV / Spend";
        case "CPM":
            return "CPM";
        case "Impressions":
            return "CPM"
        case "Views":
            return "CPM"
        case "ThruPlays":
            return "CPM"
        case "Leads":
            return "CPL"
        case "Purchases":
            return "Cost Per. Purchase"
        case "Sales":
            return "Cost Per. Sale";
        case "Landing Page Views":
            return "CPLPV";
        case "Link Clicks":
            return "CPLC";
        case "Free Trials":
            return "CPFT";
        case "Installs":
            return "Cost Per. Install";
        case "Referrals":
            return "Cost Per. Referrals";
        case "Signups":
            return "Cost Per. Signup";
        case "Clicks":
            return "CPC";
        case "Bookings":
            return "Cost Per. Booking";
      }
    }

    setTimeSeriesWeeklyData = (weeklySegment, uniqueDates) =>
    {
        const createDateFromDateString = (dateString) => {
            const [year, month, day] = dateString.split('-');
            return new Date(2000, month - 1, day); // Using a fixed year (2000) to avoid conflicts
        };
        var modifiedUniqueDates = [];
        if (uniqueDates.length > 0)
        {
                // Generate a range of dates between selectedDateStart and selectedDateEnd
                for (let date = new Date(this.state.selectedDateStart); date <= new Date(this.state.selectedDateEnd); date.setDate(date.getDate() + 1)) {
                    const month = date.getMonth() + 1;
                    const day = date.getDate();
            
                    // Get the month and day without the year
                    const monthDayString = `${month}-${day}`;
            
                    // Find the corresponding date in uniqueDates and modify the year
                    const correspondingDateString = uniqueDates.find((uniqueDate) => {
                    const uniqueDateObj = createDateFromDateString(uniqueDate);
                    const uniqueMonth = uniqueDateObj.getMonth() + 1;
                    const uniqueDay = uniqueDateObj.getDate();
                    return `${uniqueMonth}-${uniqueDay}` === monthDayString;
                    });
            
                    if (correspondingDateString) {
                        const modifiedDateString = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
                        modifiedUniqueDates.push(modifiedDateString);
                    }
                }
            var weeksArray = [];
            // calculate the index of the first week because you never know which day the data starts at
            // skip if the first index is a sunday
            var weeklyEndingIndex = weeklySegment - new Date(modifiedUniqueDates[0]+"T00:00:00").getDay();
            var skipFirstWeek = true;
            // if the first day is a sunday, set weeklyEndingIndex to 1 and skipFirstWeek to false which ignores the week skip in the for loop.
            if (weeklyEndingIndex === -1)
            {
                weeklyEndingIndex = 0
                skipFirstWeek = false
            } 
            //calculate how many weeks are in the array
            var weeks = Math.ceil(modifiedUniqueDates.length / 7);
            var x = 0;
            for (var i = 0; i < weeks; i++)
            {
                var weeklyDateArray = []
                for (x; x <= (7 * (i+1) - (skipFirstWeek ? 0 : weeklyEndingIndex !== 0 ? 7 - weeklyEndingIndex : 0)); x++)
                {
                    if(uniqueDates[x] === undefined)
                    {
                        break;
                    }
                    weeklyDateArray.push(uniqueDates[x])
                    if (x === weeklyEndingIndex && skipFirstWeek)
                    {
                        x++;
                        break;
                    }
                }
                weeksArray.push(weeklyDateArray.filter(function (e){return e !== undefined}))
                skipFirstWeek = false;
            }
            //setup uniqueDates as weekly chunks
            modifiedUniqueDates = [];
            for (i = 0; i < weeksArray.length; i++)  
            {
                modifiedUniqueDates[i] = weeksArray[i][0].slice(5) + " - " + weeksArray[i][weeksArray[i].length - 1].slice(5);
            }
             return [weeksArray, modifiedUniqueDates];
        }
        return [[], []]
    }

    setMonthlyDataTimeSeriesGrid = (dates, isConvertToWideActive) =>
    {
        var json = []
        var timeSeriesGridWideParams = []
        var metrics = ["Spend"]
        metrics.push(this.state.conversionType)
        metrics.push(this.getConversionCalculatedHeader())
        for (var i = 0; i < dates.length; i++)
        {
            var timeSeriesCostGrid = 0
            var timeSeriesKPIGrid = 0
            var timeSeriesMetricCalculationGrid = 0
            var timeSeriesDate = this.state.timeSeriesDate.map((x) => {return new Date(x).toLocaleDateString('en-US', {timeZone:'UTC',month: 'short'})})
            if (this.state.timeSeriesParams["timeSeriesCampaignGrouping"] === "All")
            {
                for (var x = 0; x < timeSeriesDate.length; x++)
                {
                    if (timeSeriesDate[x] !== dates[i])
                        continue;
                    timeSeriesCostGrid = parseInt(timeSeriesCostGrid) + parseInt(this.state.timeSeriesCost[x])
                    timeSeriesKPIGrid = parseInt(timeSeriesKPIGrid) + parseInt(this.state.timeSeriesKPI[x])
                }
            }
            
            else
            { 
                var campaignGroupingIndexArray = this.state.timeSeriesCampaignGrouping.map((e, index) => e === this.state.timeSeriesParams["timeSeriesCampaignGrouping"] ? index : '').filter(String);
                for (var x = 0; x < campaignGroupingIndexArray.length; x++)
                {
                    if (timeSeriesDate[campaignGroupingIndexArray[x]] !== dates[i])
                        continue;
                    timeSeriesCostGrid = parseInt(timeSeriesCostGrid) + parseInt(this.state.timeSeriesCost[campaignGroupingIndexArray[x]])
                    timeSeriesKPIGrid = parseInt(timeSeriesKPIGrid) + parseInt(this.state.timeSeriesKPI[campaignGroupingIndexArray[x]])
                }
            }
          
            if (this.state.conversionType === "Revenue" || this.state.conversionType === "Reach")
                timeSeriesMetricCalculationGrid = ((timeSeriesKPIGrid / timeSeriesCostGrid)).toFixed(2).toString();
            else if (this.state.conversionType === "CPM" || this.state.conversionType === "Impressions" || this.state.conversionType === "Views" || this.state.conversionType === "ThruPlays")
                timeSeriesMetricCalculationGrid = ((timeSeriesCostGrid / (timeSeriesKPIGrid/1000) )).toFixed(2).toString();
            else
                timeSeriesMetricCalculationGrid = ((timeSeriesCostGrid / timeSeriesKPIGrid )).toFixed(2).toString();
            
            if (!isConvertToWideActive)
                json.push({Month: dates[i], Spend: timeSeriesCostGrid, TimeSeriesKPI: timeSeriesKPIGrid, TimeSeriesMetricCalculated: timeSeriesMetricCalculationGrid})
            
            else
            {
                timeSeriesGridWideParams.push({Spend: timeSeriesCostGrid, [this.state.conversionType]:timeSeriesKPIGrid, [this.getConversionCalculatedHeader()]: timeSeriesMetricCalculationGrid})
            }
        
        }

        if (isConvertToWideActive)
        {
            for (i = 0; i < metrics.length; i++)
            {
                json.push({Metric: metrics[i]})
                for (x = 0; x < dates.length; x++)
                {
                    json[i][dates[x]] = timeSeriesGridWideParams[x][metrics[i]];
                }
            }
        }
      
        return json;
    }

    timeSeriesCalculation = (indexArray, timeSeriesCostArray, timeSeriesKPIArray) =>
    {
        var timeSeriesCost = 0
        var timeSeriesKPI = 0
        for (var i = 0; i < indexArray.length; i++)
        {
            timeSeriesCost = parseInt(timeSeriesCost) + parseInt(timeSeriesCostArray[indexArray[i]])
            timeSeriesKPI = parseInt(timeSeriesKPI) + parseInt(timeSeriesKPIArray[indexArray[i]])
        }

        return [timeSeriesCost, timeSeriesKPI];
    }

    
    timesSeriesCampaignFilter = () =>
    {
        var timeSeriesDate = []
        var timeSeriesCostArray = []
        var timeSeriesKPIArray = []
        //get all indexes for given campaignGrouping
        var campaignGroupingIndexArray = this.state.timeSeriesCampaignGrouping.map((e, index) => e === this.state.timeSeriesParams["timeSeriesCampaignGrouping"] ? index : '').filter(String);
        for (var y = 0; y < campaignGroupingIndexArray.length; y++)
        {
            timeSeriesDate.push(this.state.timeSeriesDate[campaignGroupingIndexArray[y]])
            timeSeriesCostArray.push(this.state.timeSeriesCost[campaignGroupingIndexArray[y]])
            timeSeriesKPIArray.push(this.state.timeSeriesKPI[campaignGroupingIndexArray[y]])
        }

        return [timeSeriesDate, timeSeriesCostArray, timeSeriesKPIArray]
    }

    createWeeklyTimeSeriesData = (weeksArray, uniqueWeeks, isConvertToWideActive) =>
    {
        var metrics = ["Spend"]
        metrics.push(this.state.conversionType)
        metrics.push(this.getConversionCalculatedHeader())
        var indexArray = [];
        var timeSeriesDate = this.state.timeSeriesDate;
        var timeSeriesCostArray = this.state.timeSeriesCost;
        var timeSeriesKPIArray = this.state.timeSeriesKPI;
        var timeSeriesCost = 0;
        var timeSeriesKPI = 0;
        var timeSeriesMetricCalculated = 0
        var json = [];
        var timeSeriesGridWideParams = [];

        if (this.state.timeSeriesParams["timeSeriesCampaignGrouping"] !== "All")
        {
            var timeSeriesCampaignGroupingData = this.timesSeriesCampaignFilter()
            timeSeriesDate = timeSeriesCampaignGroupingData[0];
            timeSeriesCostArray = timeSeriesCampaignGroupingData[1];
            timeSeriesKPIArray = timeSeriesCampaignGroupingData[2];
        }

        for (var x = 0; x < weeksArray.length; x++)
        {
            for (var y = 0; y < weeksArray[x].length; y++)
            {
                indexArray = timeSeriesDate.map((e, index) => e === weeksArray[x][y] ? index : '').filter(String);
                var timeSeriesCalculationResult = this.timeSeriesCalculation(indexArray, timeSeriesCostArray, timeSeriesKPIArray)
                timeSeriesCost  = timeSeriesCost + timeSeriesCalculationResult[0]
                timeSeriesKPI = timeSeriesKPI + timeSeriesCalculationResult[1]
            }

            if (this.state.conversionType === "Revenue" || this.state.conversionType === "Reach")
                 timeSeriesMetricCalculated = ((timeSeriesKPI / timeSeriesCost)).toFixed(2).toString()
            else if (this.state.conversionType === "CPM" || this.state.conversionType === "Impressions" || this.state.conversionType === "Views" || this.state.conversionType === "ThruPlays")
                 timeSeriesMetricCalculated = ((timeSeriesCost / (timeSeriesKPI/1000) )).toFixed(2).toString();
            else
                 timeSeriesMetricCalculated = ((timeSeriesCost / timeSeriesKPI )).toFixed(2).toString()

            if (!isConvertToWideActive)
                json.push({Weeks: uniqueWeeks[x], Spend: timeSeriesCost, TimeSeriesKPI: timeSeriesKPI, TimeSeriesMetricCalculated: timeSeriesMetricCalculated}) 
            else
            {
                timeSeriesGridWideParams.push({Spend: timeSeriesCost, [this.state.conversionType]:timeSeriesKPI, [this.getConversionCalculatedHeader()]: timeSeriesMetricCalculated})
            }
            timeSeriesCost = 0;
            timeSeriesKPI = 0; 
            timeSeriesMetricCalculated = 0
        }

        
        if (isConvertToWideActive)
        {
            for (var i = 0; i < metrics.length; i++)
            {
                json.push({Metric: metrics[i]})
                for (var x = 0; x < uniqueWeeks.length; x++)
                {
                    json[i][uniqueWeeks[x]] = timeSeriesGridWideParams[x][metrics[i]];
                }
            }
        }
       return json
    }

    fetchDataTimeSeriesGrid = (isConvertToWideActive) =>
    {
        if (this.state.timeSeriesCost.length !== 0)
        {
            var timeSeriesGridDates = this.state.timeSeriesDate.filter((v, i, a) => a.indexOf(v) === i);
            var dates = timeSeriesGridDates.filter((v, i, a) => a.indexOf(v) === i);
            if (this.state.timeSeriesParams["timeSeriesSegment"] === "Monthly")
            {
                dates = dates.map((x) => {return new Date(x).toLocaleDateString('en-US', {timeZone:'UTC',month: 'short'})})
                dates = dates.filter((v, i, a) => a.indexOf(v) === i);
                return this.setMonthlyDataTimeSeriesGrid(dates, isConvertToWideActive)
            }

            if (this.state.timeSeriesParams["timeSeriesSegment"].includes("Weekly"))
            {
                var weeklySegment = this.state.timeSeriesParams["timeSeriesSegment"].includes("(Sunday - Saturday)") ? 6 : 7
                var timeSeriesWeeklyData = this.setTimeSeriesWeeklyData(weeklySegment, dates)
                var weeksArray = timeSeriesWeeklyData[0];
                var uniqueWeeks = timeSeriesWeeklyData[1];
                var weeklyTimeSeriesDataGrid = this.createWeeklyTimeSeriesData(weeksArray, uniqueWeeks, isConvertToWideActive)
                return weeklyTimeSeriesDataGrid;
            }
        }
    }

    fetchBudgetSweepData = async() =>
    {
        this.setState({isBudgetRecommendation: false})
        var apiRecommendedBudget = []
        var apiData =  await fetchApi("BUDGET_OPTIMIZATION", [this.state.selectedBudgetOutputId, this.state.selectedDateStart, this.state.selectedDateEnd, this.state.target.toLowerCase(), this.state.targetValue, this.state.budgetOptimizationAPIParams.minPercentArray, this.state.budgetOptimizationAPIParams.maxPercentArray,this.state.budgetOptimizationAPIParams.minSpendArray, this.state.budgetOptimizationAPIParams.maxSpendArray, this.state.accountId, this.state.budgetOptimizationAPIParams.campaignGroupingArray, this.state.budgetOptimizationAPIParams.isDollarConstraint, this.state.budgetOptimizationAPIParams.disabledIds])
        if (apiData.message !== undefined || apiData.error !== undefined)
        {
            if (!this.state.isError)
                await this.setState({isError:true, errorMessage: apiData.message === undefined ? apiData.error : apiData.message})
        }
        else
        {
            for (var x = 0; x < apiData.length; x++)
            {
                apiData[x].adj_spend = (((parseFloat(apiData[x].rec_budget) - parseFloat(apiData[x].avg_hist_spend)) / parseFloat(apiData[x].avg_hist_spend))*100).toFixed(0).toString() + "%"

                if (this.state.conversionType === "Revenue" || this.state.conversionType === "Reach")
                    apiData[x].est_roi = (parseFloat(apiData[x].rec_kpi) / parseFloat(apiData[x].rec_budget)).toFixed(2)
                else if (this.state.conversionType === "CPM" || this.state.conversionType === "Impressions" || this.state.conversionType === "Views" || this.state.conversionType === "ThruPlays")
                    apiData[x].est_roi = (parseFloat(apiData[x].rec_budget) / (parseFloat(apiData[x].rec_kpi)/1000)).toFixed(2)
                else
                    apiData[x].est_roi = (parseFloat(apiData[x].rec_budget)/ parseFloat(apiData[x].rec_kpi)).toFixed(2)                
            }
            
            //filter out any potential disabled rows, incase of race conditions
            apiData = apiData.filter(item => !this.state.disabledCampaignGroupIds.includes(item.id));

            for (var i = 0; i < apiData.length; i++)
            {
                apiData[i].enabled = true
            }

            for (var i = 0; i < apiData.length; i++)
            {
                apiRecommendedBudget.push({rec_budget: apiData[i].rec_budget, id:apiData[i].id})
            }

            //add back any disabled rows with 0 values
            for (var i = 0; i < this.state.disabledRows.length; i++)
            {
                apiData.splice(this.state.disabledRows[i].rowIndex, 0, {adj_spend:'0', avg_hist_spend:'0', est_roi:'0', id:this.state.disabledRows[i].id, max_percent:"0.80", max_spend:this.state.disabledRows[i].maxSpend, min_percent:"0.00", min_spend:"0.00", name:this.state.disabledRows[i].campaignName, rec_budget:0, rec_kpi:0, warnings: [], enabled: false})
            }

    
        

            await this.setState({recommendedBudget: apiRecommendedBudget, isError:false, errorMessage: '', apiData: apiData})
        }
    }

    fetchResponseCurveData = async (campaignGroupingArray, selectedBudgetPlanId, isOptimized) =>
    {
        var apiData = []
        if (!isOptimized)
            apiData = await fetchApi("RESPONSE_CURVES", [selectedBudgetPlanId])
        else
            apiData = await fetchApi("RESPONSE_CURVES_OPTIMIZED", [this.state.budgetOptimizationAPIParams.campaignGroupingArray.filter(x => !this.state.disabledCampaignGroupIds.includes(x)), this.state.selectedDateStart, this.state.selectedDateEnd])
        apiData = apiData.map(x => {if(x.kpi_value < 0){x.kpi_value = 0}return x;})
        //apiData = apiData.sort((a, b) => a.campaign_group_name.toString().localeCompare(b.campaign_group_name) || a.cost - b.cost);
        return apiData;
    }

    fetchMarginalOverallData = async (isOptimized) =>
    {
        var apiData = []
        if (!isOptimized)
            var apiData = await fetchApi("OVERALL_MARGINAL", [this.state.selectedBudgetOutputId])
        else
            var apiData =  await fetchApi("OVERALL_MARGINAL_OPTIMIZED", [this.state.selectedDateStart, this.state.selectedDateEnd, this.state.budgetOptimizationAPIParams.minPercentArray, this.state.budgetOptimizationAPIParams.maxPercentArray,this.state.budgetOptimizationAPIParams.minSpendArray, this.state.budgetOptimizationAPIParams.maxSpendArray, this.state.budgetOptimizationAPIParams.isDollarConstraint, this.state.target.toLowerCase(), this.state.targetValue, this.state.budgetOptimizationAPIParams.campaignGroupingArray, this.state.budgetOptimizationAPIParams.disabledIds])

        var costArray = apiData.map(e => e.cost)
        var kpiArray = apiData.map(e => e.kpi_value)
        var marginalKpiArray = apiData.map(e => e.marginal_kpi)
        return [costArray, kpiArray, marginalKpiArray]
        //return apiData;
    }

    // promisedSetState = async(newState) =>
    // {
    //     new Promise(resolve => this.setState(newState, resolve));
    // }

    setBudgetOptimizationParams = async (gridApi, isDollarConstraint, groupId, type, campaignName, rowIndex, maxSpend) => 
    {
      var disabledCampaignGroupIds = this.state.disabledCampaignGroupIds
      var disabledRows = this.state.disabledRows
      if (type === "enable")
      {
        disabledRows = disabledRows.filter(x => x.id !== groupId)
        disabledCampaignGroupIds = disabledCampaignGroupIds.filter(id => id !== groupId);
      }
         
        
      else if (type === "disable")
      {
        disabledRows.push({rowIndex: rowIndex, campaignName: campaignName, id: groupId, maxSpend: maxSpend})
        disabledCampaignGroupIds.push(groupId)
      }
        
      
      if(gridApi)
      {
        var budgetOptimizationAPIParams = {maxPercentArray : [], minPercentArray : [], maxSpendArray : [], minSpendArray : [], campaignGroupingArray : [], isDollarConstraint: isDollarConstraint, disabledIds: disabledCampaignGroupIds}
        for (var i = 0; i < gridApi.getDisplayedRowCount(); i++)
        {
            
                budgetOptimizationAPIParams.minSpendArray.push(gridApi.getRowNode(i).data.min_spend)
                budgetOptimizationAPIParams.maxSpendArray.push(gridApi.getRowNode(i).data.max_spend)
                budgetOptimizationAPIParams.minPercentArray.push(gridApi.getRowNode(i).data.min_percent)
                budgetOptimizationAPIParams.maxPercentArray.push(gridApi.getRowNode(i).data.max_percent)
                budgetOptimizationAPIParams.campaignGroupingArray.push(gridApi.getRowNode(i).data.id)
           
      
        }
        if (JSON.stringify(budgetOptimizationAPIParams) === JSON.stringify(this.state.budgetOptimizationAPIParams))
            return;
        this.setState({
            budgetOptimizationAPIParams : budgetOptimizationAPIParams,
            disabledCampaignGroupIds: disabledCampaignGroupIds,
            disabledRows: disabledRows
        })
      }
    }

    refreshBudgetRecommendation = () =>
    {
        if (this.state.selectedDateStart !== null && this.state.selectedDateEnd !== null && this.state.targetValue !== null)
        {
            this.setState({isBudgetRecommendation: true})
        }
    }

    switchBudgetOutputResult = (e) =>
    {
        this.props.switchBudgetOutputResult(e, this.state.recentBudgetInputs)
    }

    didBudgetParamatersReset = (e) =>
    {
        this.setState({resetBudgetParameters: false})
    }

    resetRecommendationValues = async () =>
    {
        this.setState({isBudgetRecommendation:false, resetBudget:true, selectedDateStart: null, selectedDateEnd: null, targetValue: '', isError:false, errorMessage: '', json: []})
        await this.fetchData();
    }

    changeTimeSeriesParams = (e) =>
    {
        var timeSeriesParams = this.state.timeSeriesParams
        timeSeriesParams[e.target.id] = e.target.value
        this.setState({timeSeriesParams: timeSeriesParams})
    }

    backToBudgetResults = () =>
    {
        this.props.backToBudgetResults()
    }

    render() {
        let targetTooltip = ["Specify a budget, KPI, or efficiency target. Budget target example:" ,<br/>, "setting a budget target of 1,000,000 (dollars) will output" ,<br/>, "the most efficient way to allocate the investment (meeting your constraints) over the specified time period." ,<br/>, "KPI target example: setting a KPI target of 5,000 (e.g. leads), will output the minimum budget required to meet that goal, optimally allocated across your initiatives." ,<br/>, "KPI / Spend and Spend / KPI example: specify this if you have no maximum budget, but have an efficiency target, such as an ROAS of 2." ,<br/>," The output will display the maximum investment that can be made while not exceeding this efficiency goal. Note, KPI / Spend would be applicable to a metric such as ROAS, while Spend / KPI would be applicable to a metric such as cost per lead."];
        let dateRangeTooltip = "Specify the time period (start and end dates) the budget plan should generate budget allocation recommendations and forecasts for. For example, to understand how to best allocate one million dollars for your initiatives next month, enter 1,000,000 into the Target field with Budget selected, then select the first day of next month (start) and last day of next month (end) in the Date Range module.";
        var {isError, errorMessage} = this.state
        return(
            <React.Fragment>
                <div  style={{paddingLeft:"50px", paddingRight:"50px"}} className="row">
                    <button onClick={this.backToBudgetResults} style={{fontSize:12, padding: 5}} className='btn btn-primary'>Back to Budget Results</button>
                </div>
                <div  style={{paddingLeft:"50px", paddingRight:"50px"}} className="row">
                    
                    {/* <div className="col-md-2">
                        <span className="budget-inputs">Recent Budget Inputs</span>
                        <ul className="recent-budget-list">
                            {
                                Object.keys(this.state.recentBudgetInputs).map((key) => {
                                return <li onClick={this.switchBudgetOutputResult}  className="recent-budget-list-item" value={this.state.recentBudgetInputs[key].name ? this.state.recentBudgetInputs[key].name : "" } key={this.state.recentBudgetInputs[key].id ? this.state.recentBudgetInputs[key].id : ""}>{this.state.recentBudgetInputs[key].name ? this.state.recentBudgetInputs[key].name : ""}</li>;
                            })}
                        </ul>
                    </div> */}
                    <div className="col-sm-6">
                        <span className="grid-header">{this.state.selectedBudgetOutput}</span>
                        <GroupedBarChart conversionType={this.state.conversionType} selectedBudgetPlanId = {this.state.selectedBudgetOutputId}></GroupedBarChart>
                    </div>
                    <div className="col-sm-6">
                        <LineChart viewType="Grouping" modelFrequency={this.state.modelFrequency} conversionType={this.state.conversionType} selectedDateStart={this.state.selectedDateStart} selectedDateEnd={this.state.selectedDateEnd} chartType="ResponseCurves" fetchResponseCurveData = {this.fetchResponseCurveData} recommendedBudget = {this.state.recommendedBudget} selectedBudgetPlanId = {this.state.selectedBudgetOutputId}></LineChart>
                    </div>
                </div>
                <br />
                <br />
                <BudgetRecommendationParameters didBudgetParamatersReset = {this.didBudgetParamatersReset} resetBudgetParameters={this.state.resetBudgetParameters} resetRecommendationValues={this.resetRecommendationValues} handleDateChangeStart={this.handleDateChangeStart} target={this.state.target} targetDropDownChange={this.targetDropDownChange} targetValueChange={this.targetValueChange} handleDateChangeEnd={this.handleDateChangeEnd}></BudgetRecommendationParameters>
                <div style={{paddingLeft:"50px", paddingRight:"50px"}} className="row">
                    
                    <div className="col-sm-12">
                        <span className="grid-header">Scenario Planner</span>
                        {
                            isError ? 
                            <div className="error-text"> {errorMessage} </div> :
                            <div> </div>
                        }
                        <div style={{height:'400px'}}><MainGridComponent columnWidth={this.state.columnWidth} conversionType={this.state.conversionType} apiData={this.state.apiData} refreshBudgetRecommendation = {this.refreshBudgetRecommendation} resetBudget = {this.state.resetBudget} accountId = {this.state.accountId} fetchBudgetSweepData={this.fetchBudgetSweepData} isBudgetRecommendation={this.state.isBudgetRecommendation} fetchData={this.fetchData} selectedBudgetOutputId = {this.state.selectedBudgetOutputId} pagetype="BudgetResultsOutput" gridtype="recentBudgetPlans" setBudgetOptimizationParams = {this.setBudgetOptimizationParams}></MainGridComponent></div>
                        <br />
                        <div className="row">
                            <div className="col-sm-12">
                                <LineChart viewType="Grouping" modelFrequency={this.state.modelFrequency} conversionType={this.state.conversionType} accountId = {this.state.accountId} selectedDateStart={this.state.selectedDateStart} selectedDateEnd={this.state.selectedDateEnd} selectedBudgetPlanId = {this.state.selectedBudgetOutputId} chartType="MarginalROAS" fetchResponseCurveData = {this.fetchResponseCurveData} recommendedBudget = {this.state.recommendedBudget}></LineChart>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6">
                                <LineChart fetchMarginalOverallData={this.fetchMarginalOverallData} modelFrequency={this.state.modelFrequency} conversionType={this.state.conversionType} accountId = {this.state.accountId} selectedDateStart={this.state.selectedDateStart} selectedDateEnd={this.state.selectedDateEnd} recommendedBudget = {this.state.recommendedBudget} selectedBudgetPlanId = {this.state.selectedBudgetOutputId} chartType="MarginalROASOverall" budgetOptimizationAPIParams={this.state.budgetOptimizationAPIParams}></LineChart>
                            </div>
                            <div className="col-sm-6">
                                <LineChart fetchMarginalOverallData={this.fetchMarginalOverallData} modelFrequency={this.state.modelFrequency} conversionType={this.state.conversionType} accountId = {this.state.accountId} selectedDateStart={this.state.selectedDateStart} selectedDateEnd={this.state.selectedDateEnd} chartType="OverallResponseCurve" selectedBudgetPlanId = {this.state.selectedBudgetOutputId} recommendedBudget = {this.state.recommendedBudget}  ></LineChart>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div style={{paddingLeft:"50px", paddingRight:"50px", height:'400px'}} className="row">
                    
                    <div  className="col-sm-12">
                        <div style={{display:"flex", marginBottom:"15px"}}>
                            <span> <b>Segment:</b>  </span>
                            <select id="timeSeriesSegment" className='form-control' style={{width: "250px", marginLeft: "15px"}} onChange={this.changeTimeSeriesParams}>
                                <option value="Weekly (Sunday - Saturday)">Weekly (Sunday - Saturday)</option>
                                <option value="Weekly (Monday - Sunday)">Weekly (Monday - Sunday)</option>
                                <option value="Monthly">Monthly</option>
                            </select>
                            <span style={{marginLeft:"15px"}}> <b>Campaign Grouping:</b>  </span>
                            <select id="timeSeriesCampaignGrouping" className='form-control' style={{width: "250px", marginLeft: "15px"}} onChange={this.changeTimeSeriesParams}>
                                <option value="All">All</option>
                                {this.state.timeSeriesCampaignGrouping.filter((v, i, a) => a.indexOf(v) === i).map((e) => {return <option key={e} value={e}>{e}</option>})}
                            </select>
                        </div>
                        <LineChart timesSeriesCampaignFilter = {this.timesSeriesCampaignFilter} timeSeriesCalculation = {this.timeSeriesCalculation} setTimeSeriesWeeklyData={this.setTimeSeriesWeeklyData} modelFrequency={this.state.modelFrequency} conversionType={this.state.conversionType} recommendedBudget = {this.state.recommendedBudget} fetchDataTimeSeriesLineChart = {this.fetchDataTimeSeriesLineChart} selectedBudgetPlanId = {this.state.selectedBudgetOutputId} chartType="TimeSeries"  timeSeriesDate = {this.state.timeSeriesDate} timeSeriesCost={this.state.timeSeriesCost} timeSeriesKPI={this.state.timeSeriesKPI} timeSeriesCampaignGrouping={this.state.timeSeriesCampaignGrouping} timeSeriesParams={this.state.timeSeriesParams}></LineChart>
                        <span className="grid-header">Time Series Grid</span>
                        <div style={{height:'400px'}}><MainGridComponent conversionType = {this.state.conversionType} timeSeriesParams={this.state.timeSeriesParams} timeSeriesCost={this.state.timeSeriesCost} accountId = {this.state.accountId} fetchData={this.fetchDataTimeSeriesGrid} selectedBudgetOutputId = {this.state.selectedBudgetOutputId} pagetype="BudgetResultsOutput" gridtype="timeSeriesGrid"></MainGridComponent></div>
                    </div>
                    
                </div>
            </React.Fragment>
         );
    }
}

export default CampaignGroupingTemplate
