import React, { Component } from 'react';
import MainGridComponent from './../MainGridComponent';
import '../../css/main.css';
import LineChart from './../LineChart';
import BudgetRecommendationParameters from './../BudgetRecommendationParameters';
import fetchApi from '../../FetchApi';
import Select from 'react-select';

export class CampaignTemplate extends Component {
  constructor(props) {
        super(props);
        this.state = {
            selectedBudgetOutput: this.props.selectedBudgetOutput,
            accountId: this.props.accountId,
            selectedBudgetOutputId: this.props.selectedBudgetOutputId,
            conversionType: this.props.conversionType,
            modelFrequency: this.props.modelFrequency,
            campaignGroupings: [],
            selectedCampaignGroupings: [],
            selectedCampaignGroupingsToPassToLineChart: [],
            disabledCampaignGroupIds: [],
            disabledRows: [],
            apiData: null,
            recentBudgetInputs : [{}],
            isBudgetRecommendation: false,
            resetBudget: false,
            selectedDateStart: null,
            selectedDateEnd: null,
            targetValue: '',
            target: "Budget",
            columnWidth: "300px",
            resetBudgetParameters: false,
            recommendedBudget: [],
            apiData: null,
            isOptimized: false,
            isError:false, 
            errorMessage: '',
            budgetOptimizationAPIParams: {maxPercentArray : [], minPercentArray : [], maxSpendArray : [], minSpendArray : [], campaignGroupingArray : [], isDollarConstraint: false}
        };
    };

    handleDateChangeStart = (selectedDateStart, selectedDateEnd, targetValue) => {

        if (selectedDateEnd !== null && targetValue !== '')
        {
            this.setState({ selectedDateStart: selectedDateStart.format("MM/DD/YYYY"), isBudgetRecommendation: true });
        }
            
        else
        {
            this.setState({ selectedDateStart: selectedDateStart.format("MM/DD/YYYY"), isBudgetRecommendation: false});
        }
           
      }

    handleDateChangeEnd = (selectedDateStart, selectedDateEnd, targetValue) => {
        if (selectedDateStart !== null && targetValue !== '')
        {
            this.setState({ selectedDateEnd: selectedDateEnd.format("MM/DD/YYYY"), isBudgetRecommendation: true});
        }
            
        else
        {
            this.setState({ selectedDateEnd: selectedDateEnd.format("MM/DD/YYYY"), isBudgetRecommendation: false});
        }
            
      }

    targetValueChange = (selectedDateStart, selectedDateEnd, targetValue) =>
    {
        if (selectedDateStart !== null && selectedDateEnd !== null)
        {
            this.setState({ targetValue: targetValue, isBudgetRecommendation: true });
        }
         
        else
        {
            this.setState({ targetValue: targetValue, isBudgetRecommendation: false });
        }
          
    }

    //fix this later not to set state so many times
    targetDropDownChange = (selectedDateStart, selectedDateEnd, targetValue, target) =>
    {
        this.setState({ target: target, isBudgetRecommendation: false });
        if (selectedDateStart !== null && selectedDateEnd !== null && targetValue !== '')
        {
            this.setState({isBudgetRecommendation: true})
        }
           
    }
    componentDidMount = async() =>
    {
        var apiData =  await fetchApi("BUDGET_RESULTS", [this.state.accountId])
        var campaign_grouping_data = await fetchApi("GET_CAMPAIGN_GROUPS_FOR_DROPDOWN", [this.state.selectedBudgetOutputId])
        var campaignGroupings = []
        var selectedCampaignGroupings = []
        for (var i = 0; i < campaign_grouping_data.length; i++)
        {
            campaignGroupings.push({value:campaign_grouping_data[i].id, label:campaign_grouping_data[i].name})
            if (i < 5)
                selectedCampaignGroupings.push({value:campaign_grouping_data[i].id, label:campaign_grouping_data[i].name})
        }
        
        var recentBudgetInputs = []

        for (var i = 0; i < 3; i++)
        {
            if (i === apiData.length)
                break;
                var recentBudgetInputItem = {id: apiData[i].id, name: apiData[i].name, campaignGroupCount: apiData[i].campaign_group_count, conversionType: apiData[i].conversion_type, modelFrequency: apiData[i].model_frequency}
            recentBudgetInputs.push(recentBudgetInputItem)
        }
        
        this.setState({recentBudgetInputs : recentBudgetInputs, campaignGroupings: campaignGroupings, selectedCampaignGroupings: selectedCampaignGroupings, selectedCampaignGroupingsToPassToLineChart: selectedCampaignGroupings})
    }

    componentDidUpdate = async(prevProps, prevState) =>
    {
        if (this.props.selectedBudgetOutputId !== prevProps.selectedBudgetOutputId)
        {
            var apiData =  await fetchApi("BUDGET_RESULTS", [this.state.accountId])
            var campaign_grouping_data = await fetchApi("GET_CAMPAIGN_GROUPS_FOR_DROPDOWN", [this.props.selectedBudgetOutputId])
            var campaignGroupings = []
            var selectedCampaignGroupings = []
            for (var i = 0; i < campaign_grouping_data.length; i++)
            {
                campaignGroupings.push({value:campaign_grouping_data[i].id, label:campaign_grouping_data[i].name})
                if (i < 5)
                    selectedCampaignGroupings.push({value:campaign_grouping_data[i].id, label:campaign_grouping_data[i].name})
            }
            
            var recentBudgetInputs = []
    
            for (var i = 0; i < 3; i++)
            {
                if (i === apiData.length)
                    break;
                    var recentBudgetInputItem = {id: apiData[i].id, name: apiData[i].name, campaignGroupCount: apiData[i].campaign_group_count, conversionType: apiData[i].conversion_type, modelFrequency: apiData[i].model_frequency}
                recentBudgetInputs.push(recentBudgetInputItem)
            }
           
            this.setState({ modelFrequency: this.props.modelFrequency, conversionType: this.props.conversionType, selectedBudgetOutputId: this.props.selectedBudgetOutputId, selectedBudgetOutput: this.props.selectedBudgetOutput, resetBudgetParameters: true, recommendedBudget : [], isBudgetRecommendation:false, selectedDateStart: null, selectedDateEnd: null, targetValue: '', json: [], isError:false, errorMessage: '', recentBudgetInputs : recentBudgetInputs, campaignGroupings: campaignGroupings, selectedCampaignGroupings: selectedCampaignGroupings, selectedCampaignGroupingsToPassToLineChart: selectedCampaignGroupings})
        }
    }

    switchBudgetOutputResult = (e) =>
    {
        this.props.switchBudgetOutputResult(e, this.state.recentBudgetInputs)
    }

    fetchData = async() =>
    {
        var apiRecommendedBudget = []
        var apiData =  await fetchApi("RECENT_BUDGET_RECOMMENDATIONS", [this.state.selectedBudgetOutputId])
        for (var x = 0; x < apiData.length; x++)
        {
            apiData[x].rec_budget = 0
            apiData[x].avg_hist_spend = 0
            apiData[x].adj_spend = 0
            apiData[x].rec_kpi = 0
            apiData[x].est_roi = 0
            apiData[x].min_spend = "0"
            apiData[x].max_spend = "0"
            apiData[x].enabled = true
        }
        for (var i = 0; i < apiData.length; i++)
        {
            apiRecommendedBudget.push({rec_budget: apiData[i].rec_budget, id:apiData[i].id})
        }
        //apiData = apiData.map(obj => {return {...obj, max_spend:obj.upper_quant_bounds_budget}})
        var campaignNames = apiData.map(x => x.name).filter((value, index, self) => self.indexOf(value) === index)
        var columnWidth = this.getColumnWidth(campaignNames);
        this.setState({columnWidth: columnWidth, apiData: apiData, resetBudget:false, resetBudgetParameters:false, recommendedBudget: apiRecommendedBudget})
    }

    getColumnWidth = (campaignNames) =>
    {
        var longestString = Math.max(...(campaignNames.map(el => el.length)));
        if (longestString <= 10)
            return "200px";
        if (longestString <= 20)
            return "250px";
        if (longestString <= 30)
            return "300px";
        if (longestString <= 40)
            return "350px";
        if (longestString <= 60)
            return "400px";
        if (longestString <= 70)
            return "550px";
        if (longestString <= 80)
            return "600px";
        if (longestString <= 90)
            return "650px";
        if (longestString <= 100)
            return "700px";
        if (longestString <= 110)
            return "750px";
    }

    getConversionCalculatedHeader = () =>
    {
      switch (this.state.conversionType)
      {
        case "Revenue":
            return "ROAS";
        case "Conversions":
            return "CPA";
        case "Reach":
            return "KPI / Spend";
        case "New Visitors":
            return "NV / Spend";
        case "CPM":
            return "CPM";
        case "Impressions":
            return "CPM"
        case "Views":
            return "CPM"
        case "ThruPlays":
            return "CPM"
        case "Leads":
            return "CPL"
        case "Purchases":
            return "Cost Per. Purchase"
        case "Sales":
            return "Cost Per. Sale";
        case "Landing Page Views":
            return "CPLPV";
        case "Link Clicks":
            return "CPLC";
        case "Free Trials":
            return "CPFT";
        case "Installs":
            return "Cost Per. Install";
        case "Referrals":
            return "Cost Per. Referrals";
        case "Signups":
            return "Cost Per. Signup";
        case "Clicks":
            return "CPC";
        case "Bookings":
            return "Cost Per. Booking";
      }
    }


    fetchBudgetSweepData = async() =>
    {
        this.setState({isBudgetRecommendation: false})
        var apiRecommendedBudget = []
        var apiData =  await fetchApi("BUDGET_OPTIMIZATION", [this.state.selectedBudgetOutputId, this.state.selectedDateStart, this.state.selectedDateEnd, this.state.target.toLowerCase(), this.state.targetValue, this.state.budgetOptimizationAPIParams.minPercentArray, this.state.budgetOptimizationAPIParams.maxPercentArray,this.state.budgetOptimizationAPIParams.minSpendArray, this.state.budgetOptimizationAPIParams.maxSpendArray, this.state.accountId, this.state.budgetOptimizationAPIParams.campaignGroupingArray, this.state.budgetOptimizationAPIParams.isDollarConstraint, this.state.budgetOptimizationAPIParams.disabledIds])
        if (apiData.message !== undefined || apiData.error !== undefined)
        {
            if (!this.state.isError)
                await this.setState({isError:true, errorMessage: apiData.message === undefined ? apiData.error : apiData.message})
        }
        else
        {
            for (var x = 0; x < apiData.length; x++)
            {
                apiData[x].adj_spend = (((parseFloat(apiData[x].rec_budget) - parseFloat(apiData[x].avg_hist_spend)) / parseFloat(apiData[x].avg_hist_spend))*100).toFixed(0).toString() + "%"
                if (this.state.conversionType === "Revenue" || this.state.conversionType === "Reach")
                    apiData[x].est_roi = (parseFloat(apiData[x].rec_kpi) / parseFloat(apiData[x].rec_budget)).toFixed(2)
                else if (this.state.conversionType === "CPM" || this.state.conversionType === "Impressions" || this.state.conversionType === "Views" || this.state.conversionType === "ThruPlays")
                    apiData[x].est_roi = (parseFloat(apiData[x].rec_budget) / (parseFloat(apiData[x].rec_kpi)/1000)).toFixed(2)
                else
                    apiData[x].est_roi = (parseFloat(apiData[x].rec_budget)/ parseFloat(apiData[x].rec_kpi)).toFixed(2)        
            }
            

            //filter out any potential disabled rows, incase of race conditions
            apiData = apiData.filter(item => !this.state.disabledCampaignGroupIds.includes(item.id));

            for (var i = 0; i < apiData.length; i++)
            {
                apiData[i].enabled = true
            }

            for (var i = 0; i < apiData.length; i++)
            {
                apiRecommendedBudget.push({rec_budget: apiData[i].rec_budget, id:apiData[i].id})
            }
            
            for (var i = 0; i < this.state.disabledRows.length; i++)
            {
                apiData.splice(this.state.disabledRows[i].rowIndex, 0, {adj_spend:'0', avg_hist_spend:'0', est_roi:'0', id:this.state.disabledRows[i].id, max_percent:"0.80", max_spend:this.state.disabledRows[i].maxSpend, min_percent:"0.00", min_spend:"0.00", name:this.state.disabledRows[i].campaignName, rec_budget:0, rec_kpi:0, warnings: [], enabled: false})
            }

           

            await this.setState({recommendedBudget: apiRecommendedBudget, isError:false, errorMessage: '', apiData: apiData})
        }
    }

    fetchResponseCurveData = async (campaignGroupingArray, selectedBudgetPlanId, isOptimized) =>
    {
        var apiData = []
        var selectedCampaignGroupIds = this.state.selectedCampaignGroupings.map(x => x.value)
        if (!isOptimized)
            apiData = await fetchApi("RESPONSE_CURVES_CAMPAIGN_LEVEL", [selectedBudgetPlanId, selectedCampaignGroupIds])
        else{
            apiData = await fetchApi("RESPONSE_CURVES_OPTIMIZED", [campaignGroupingArray.map(x => x.value).filter(x => !this.state.disabledCampaignGroupIds.includes(x)), this.state.selectedDateStart, this.state.selectedDateEnd])
        }
            
        apiData = apiData.map(x => {if(x.kpi_value < 0){x.kpi_value = 0}return x;})
        //apiData = apiData.sort((a, b) => a.campaign_group_name.toString().localeCompare(b.campaign_group_name) || a.cost - b.cost);
        return apiData;
    }

    fetchMarginalOverallData = async (isOptimized) =>
    {
        var apiData = []
        if (!isOptimized)
            var apiData = await fetchApi("OVERALL_MARGINAL", [this.state.selectedBudgetOutputId])
        else
            var apiData =  await fetchApi("OVERALL_MARGINAL_OPTIMIZED", [this.state.selectedDateStart, this.state.selectedDateEnd, this.state.budgetOptimizationAPIParams.minPercentArray, this.state.budgetOptimizationAPIParams.maxPercentArray,this.state.budgetOptimizationAPIParams.minSpendArray, this.state.budgetOptimizationAPIParams.maxSpendArray, this.state.budgetOptimizationAPIParams.isDollarConstraint, this.state.target.toLowerCase(), this.state.targetValue, this.state.budgetOptimizationAPIParams.campaignGroupingArray])

        var costArray = apiData.map(e => e.cost)
        var kpiArray = apiData.map(e => e.kpi_value)
        var marginalKpiArray = apiData.map(e => e.marginal_kpi)
        return [costArray, kpiArray, marginalKpiArray]
    }

    setBudgetOptimizationParams = async (gridApi, isDollarConstraint, groupId, type, campaignName, rowIndex, maxSpend) => 
    {
      var disabledCampaignGroupIds = this.state.disabledCampaignGroupIds
      var disabledRows = this.state.disabledRows
      if (type === "enable")
      {
        disabledRows = disabledRows.filter(x => x.id !== groupId)
        disabledCampaignGroupIds = disabledCampaignGroupIds.filter(id => id !== groupId);
      }
         
        
      else if (type === "disable")
      {
        disabledRows.push({rowIndex: rowIndex, campaignName: campaignName, id: groupId, maxSpend: maxSpend})
        disabledCampaignGroupIds.push(groupId)
      }
        
      
      if(gridApi)
      {
        var budgetOptimizationAPIParams = {maxPercentArray : [], minPercentArray : [], maxSpendArray : [], minSpendArray : [], campaignGroupingArray : [], isDollarConstraint: isDollarConstraint, disabledIds: disabledCampaignGroupIds}
        for (var i = 0; i < gridApi.getDisplayedRowCount(); i++)
        {
            
                budgetOptimizationAPIParams.minSpendArray.push(gridApi.getRowNode(i).data.min_spend)
                budgetOptimizationAPIParams.maxSpendArray.push(gridApi.getRowNode(i).data.max_spend)
                budgetOptimizationAPIParams.minPercentArray.push(gridApi.getRowNode(i).data.min_percent)
                budgetOptimizationAPIParams.maxPercentArray.push(gridApi.getRowNode(i).data.max_percent)
                budgetOptimizationAPIParams.campaignGroupingArray.push(gridApi.getRowNode(i).data.id)
           
      
        }
        if (JSON.stringify(budgetOptimizationAPIParams) === JSON.stringify(this.state.budgetOptimizationAPIParams))
            return;
        this.setState({
            budgetOptimizationAPIParams : budgetOptimizationAPIParams,
            disabledCampaignGroupIds: disabledCampaignGroupIds,
            disabledRows: disabledRows
        })
      }
    }

    handleCampaignSelection = (selectedOptions) =>
    {
        if(selectedOptions.length === 0)
            selectedOptions = this.state.selectedCampaignGroupings

        this.setState({selectedCampaignGroupings: selectedOptions, selectedCampaignGroupingsToPassToLineChart: selectedOptions})
    }

    fetchSelectedCampaignData = () =>
    {
        var selectedOptions = this.state.selectedCampaignGroupings
        this.setState({selectedCampaignGroupingsToPassToLineChart: selectedOptions})
    }

    refreshBudgetRecommendation = () =>
    {
        if (this.state.selectedDateStart !== null && this.state.selectedDateEnd !== null && this.state.targetValue !== null)
        {
            this.setState({isBudgetRecommendation: true})
        }
    }

 

    didBudgetParamatersReset = (e) =>
    {
        this.setState({resetBudgetParameters: false})
    }

    resetRecommendationValues = async () =>
    {
        this.setState({isBudgetRecommendation:false, resetBudget:true, selectedDateStart: null, selectedDateEnd: null, targetValue: '', isError:false, errorMessage: '', json: []})
        await this.fetchData()
    }

    backToBudgetResults = () =>
    {
        this.props.backToBudgetResults()
    }

    render() {
        let targetTooltip = ["Specify a budget, KPI, or efficiency target. Budget target example:" ,<br/>, "setting a budget target of 1,000,000 (dollars) will output" ,<br/>, "the most efficient way to allocate the investment (meeting your constraints) over the specified time period." ,<br/>, "KPI target example: setting a KPI target of 5,000 (e.g. leads), will output the minimum budget required to meet that goal, optimally allocated across your initiatives." ,<br/>, "KPI / Spend and Spend / KPI example: specify this if you have no maximum budget, but have an efficiency target, such as an ROAS of 2." ,<br/>," The output will display the maximum investment that can be made while not exceeding this efficiency goal. Note, KPI / Spend would be applicable to a metric such as ROAS, while Spend / KPI would be applicable to a metric such as cost per lead."];
        let dateRangeTooltip = "Specify the time period (start and end dates) the budget plan should generate budget allocation recommendations and forecasts for. For example, to understand how to best allocate one million dollars for your initiatives next month, enter 1,000,000 into the Target field with Budget selected, then select the first day of next month (start) and last day of next month (end) in the Date Range module.";
        var {isError, errorMessage} = this.state
        return(
            <React.Fragment>
                <div  style={{paddingLeft:"50px", paddingRight:"50px"}} className="row">
                    <button onClick={this.backToBudgetResults}  style={{fontSize:12, padding: 5}} className='btn btn-primary'>Back to Budget Results</button>
                </div>
                <div style={{paddingLeft:"50px", paddingRight:"50px"}}  className="row">
                    {/* <div className="col-md-2">
                        <span className="budget-inputs">Recent Budget Inputs</span>
                        <ul className="recent-budget-list">
                            {
                                Object.keys(this.state.recentBudgetInputs).map((key) => {
                                return <li onClick={this.switchBudgetOutputResult}  className="recent-budget-list-item" value={this.state.recentBudgetInputs[key].name ? this.state.recentBudgetInputs[key].name : "" } key={this.state.recentBudgetInputs[key].id ? this.state.recentBudgetInputs[key].id : ""}>{this.state.recentBudgetInputs[key].name ? this.state.recentBudgetInputs[key].name : ""}</li>;
                            })}
                        </ul>
                    </div> */}
                    <div className="col-sm-12">
                        <div className='row'>
                            <div className='col-lg-12'>
                                <span className="grid-header">{this.state.selectedBudgetOutput}</span> <br />
                                <div style={{display: 'inline-block'}}> <span className='response-curve-selector-text'>Response curve selector</span></div> &nbsp;
                                <div style={{display: 'inline-block'}}><Select
                                        isMulti
                                        name="campaignGroupings"
                                        options={this.state.campaignGroupings}
                                        value={this.state.selectedCampaignGroupings}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onBlur={this.fetchSelectedCampaignData} //change this to point to the response curve recalculated function
                                        onChange={this.handleCampaignSelection}
                                        isOptionDisabled = {() => this.state.selectedCampaignGroupings.length >= 5}
                                        isClearable = {false}
                                    />
                                </div>
                            </div>
                        </div>
                         <div className="row">
                            <div className="col-sm-6"><LineChart viewType="Campaign" selectedCampaignGroupings={this.state.selectedCampaignGroupingsToPassToLineChart} modelFrequency={this.state.modelFrequency} conversionType={this.state.conversionType} selectedDateStart={this.state.selectedDateStart} selectedDateEnd={this.state.selectedDateEnd} chartType="ResponseCurves" fetchResponseCurveData = {this.fetchResponseCurveData} recommendedBudget = {this.state.recommendedBudget} selectedBudgetPlanId = {this.state.selectedBudgetOutputId}></LineChart></div>
                            <div className="col-sm-6"><LineChart viewType="Campaign" selectedCampaignGroupings={this.state.selectedCampaignGroupingsToPassToLineChart} modelFrequency={this.state.modelFrequency} conversionType={this.state.conversionType} accountId = {this.state.accountId} selectedDateStart={this.state.selectedDateStart} selectedDateEnd={this.state.selectedDateEnd} selectedBudgetPlanId = {this.state.selectedBudgetOutputId} chartType="MarginalROAS" fetchResponseCurveData = {this.fetchResponseCurveData} recommendedBudget = {this.state.recommendedBudget}></LineChart></div>
                         </div>
                         
                    </div>
                </div> 
                <br />
                <br />
                <BudgetRecommendationParameters didBudgetParamatersReset = {this.didBudgetParamatersReset} resetBudgetParameters={this.state.resetBudgetParameters} resetRecommendationValues={this.resetRecommendationValues} handleDateChangeStart={this.handleDateChangeStart} target={this.state.target} targetDropDownChange={this.targetDropDownChange} targetValueChange={this.targetValueChange} handleDateChangeEnd={this.handleDateChangeEnd}></BudgetRecommendationParameters>
                <div style={{paddingLeft:"50px", paddingRight:"50px"}}  className="row">
                   
                    <div className="col-md-12">
                        <span className="grid-header">Scenario Planner</span>
                        {
                            isError ? 
                            <div className="error-text"> {errorMessage} </div> :
                            <div> </div>
                        }
                        <div style={{height:'400px'}}><MainGridComponent columnWidth={this.state.columnWidth} conversionType={this.state.conversionType} apiData={this.state.apiData} refreshBudgetRecommendation = {this.refreshBudgetRecommendation} resetBudget = {this.state.resetBudget} accountId = {this.state.accountId} fetchBudgetSweepData={this.fetchBudgetSweepData} isBudgetRecommendation={this.state.isBudgetRecommendation} fetchData={this.fetchData} selectedBudgetOutputId = {this.state.selectedBudgetOutputId} pagetype="BudgetResultsOutput" gridtype="recentBudgetPlans" setBudgetOptimizationParams = {this.setBudgetOptimizationParams}></MainGridComponent></div>
                        <div className="row">
                            
                        </div>
                    </div>
                    <div className="col-md-2"></div>
                </div>
        
            </React.Fragment>
         );
    }
}

export default CampaignTemplate
