import React from "react";
import AnimationRevealPage from "./../helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "./../components/headers/light.js";
import Footer from "./../components/footers/FiveColumnWithInputForm.js";
import MainFeature1 from "./../components/features/TwoColWithButton.js";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "./../components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "./../components/cards/ProfileThreeColGrid.js";
import GlobalStyles from '../styles/GlobalStyles';
import SupportIconImage from "./../images/support-icon.svg";
import ShieldIconImage from "./../images/shield-icon.svg";
import CustomerLoveIconImage from "./../images/simple-icon.svg";
import mobile from "./../images/caliper-mobile-licensed.png";
import marginal from "./../images/marginal-efficiency.png";
import laptop from "./../images/caliper-laptop-licensed.png";
import finalChart from "./../images/finalChart.png";

const Subheading = tw.span`uppercase tracking-wider text-sm text-custom-100`;
export default ({setPage = setPage}) => {
  const handlePageChange = (pageType) =>
  {
    setPage(pageType)
  }
  return (
    <><GlobalStyles />
    <AnimationRevealPage>
      <Header handlePageChange={handlePageChange}  />
      <MainFeature1
        subheading={<Subheading>WHY</Subheading>}
        heading="Most marketers overlook the importance of within-channel allocation"
        imageSrc={laptop}
        description="Often marketers shift investment to initiatives with the highest overall KPI efficiency without understanding the marginal ROI at the increased spend level. Even automated AI algorithms frequently bias investment to high volume campaigns."
      />
      <MainFeature1
        subheading={<Subheading>OUR STRENGTH</Subheading>}
        heading="Highly granular marginal efficiency modeling"
        description="Since allocation optimizations are generated one platform at a time pixel-tracked KPI data is utilized, enabling precise marginal ROI estimates and highly granular analyses that maximize your performance."
        imageSrc={marginal}
        textOnLeft={false}
      />
      <MainFeature1
        subheading={<Subheading>HOW</Subheading>}
        heading="Generate optimization scenarios on-demand through a user-friendly UI"
        imageSrc={mobile}
        description="Budgets, planning horizons, and business constraints change continuously. Fortunately, Caliper's UI allows users to run endless scenarios with ease."
      />
      <MainFeature1
        subheading={<Subheading>OUR RESULT</Subheading>}
        heading="19% avg. KPI efficiency lift while maintaining investment levels"
        description="Planning and forecasting is great, but Caliper's key benefit is driving performance through investment allocation optimizations. With newly gained efficiencies, clients often increase investment to maximize their KPI."
        imageSrc={finalChart}
        textOnLeft={false}
      />
      <Footer />
    </AnimationRevealPage></>
  );
};
