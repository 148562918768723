import React, { Component } from 'react';

export default class CustomTooltip extends Component {
  getReactContainerClasses() {
    const data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex)
      .data;
    var maxWarnings = []
    var minWarnings = []
    if(data.warnings && data.warnings.length > 0)
    {
      for (var i = 0; i < data.warnings.length; i++)
      {
        if (data.warnings[i].toLowerCase().includes("maximum") || data.warnings[i].toLowerCase().includes("max"))
          maxWarnings.push(data.warnings[i])
        else
          minWarnings.push(data.warnings[i])
        
      }

      if (maxWarnings.length > 0 && (this.props.colDef.headerName === "Max Perc." || this.props.colDef.headerName === "Max Spend" ))
        return ['custom-tooltip'];
      else if (minWarnings.length > 0 && (this.props.colDef.headerName === "Min Perc." || this.props.colDef.headerName === "Min Spend"))
        return ['custom-tooltip'];
      else
        return ['custom-tooltip', 'display-none'];
    }
    
    return ['custom-tooltip', 'display-none'];
  }

  render() {
    const data = this.props.api.getDisplayedRowAtIndex(this.props.rowIndex)
      .data;
        return (
        <div
            className="custom-tooltip"
            style={{ backgroundColor: this.props.color || 'white'}}
        >
            <p>
            {data.warnings}
            </p>
        </div>
        );
    }
}
