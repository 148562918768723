import React, { Component } from 'react';
import '../css/main.css';
import pabstKeyAdvantages from '../images/pabst-key-advantages.png';
import pabstDashboardImage from '../images/pabst-dashboard-image.png';

export class LearnMore extends Component {
  
  constructor (props) {
    super(props);
  }

  backToLogin = () =>
  {
    this.props.backToLogin();
  }

  render () {

    return (
        <>
        <div className='learn-more-container'>
            <div className='learn-more-text' style={{fontSize:"28px", cursor:"default"}}>Agile, Digital-First Media Measurement</div>
            <div className='learn-more-text' style={{float:"right"}} onClick={this.backToLogin}>Back To Login</div> <br />
            <div className='row'>
                <div className="col-lg-12">
                    <p>
                    Performing effective media measurement is an enduring challenge for brands. Traditional measurement methods have a well documented set of drawbacks, including substantial time and data requirements, lack of granular analysis, and the undervaluing of upper funnel channels. Additionally, techniques are often not adequately adapted to fit a changing advertising landscape. 
                    </p>
                </div>
                <div className="col-lg-12">
                    <p>
                    Caliper is a digital media measurement, allocation, and forecasting framework that channels the changing environment into opportunity. Employing an ensemble model (Bayesian, ridge, GAM) approach, Caliper gives users the predictive power of multiple perspectives. Built with flexibility in mind, the application is robust to incorporating emerging channels. Its digital focus provides the ability to use less data while seeing an increased precision in forecasts. Leveraging our vast number of partnerships with data providers, Caliper is able to control for competitor advertising spend, product price, and social sentiment metrics. Further, seasonal and trend indicators as well as macro economic factors are incorporated.
                    </p>
                </div>
                <div className="col-lg-12">
                    <p>
                    Let's take a deeper look at the drawbacks of traditional measurement techniques.
                    </p>
                </div>
            </div>
            <p><b>Challenges of Media Measurement</b></p> <br />
            <div className='row'>
                <div className='col-lg-6'>
                    <p><b>Marketing Mix Models</b></p>
                    <ul>
                        <li>Obtaining granular read outs</li>
                        <li>Obtaining 2+ years of usable data</li>
                        <li>Selection bias from funnel effects</li>
                    </ul>
                    <p><b>Controlled Experiments</b></p>
                    <ul>
                        <li>Number of hours required to execute</li>
                        <li>Number of weeks required to see reliable read outs</li>
                        <li>Implementation concerns</li>
                    </ul>
                </div>
                <div className='col-lg-6'>
                    <p><b>Multi-Touch Attribution</b></p>
                    <ul>
                        <li>Data sourcing and quality issues</li>
                        <li>Impact of 3P cookie loss on cross-device tracking</li>
                    </ul> <br />
                    <p><b>Unified Approach</b></p>
                    <ul>
                        <li>Limited open source information</li>
                        <li>Approach is in experimental phase</li>
                        <li>"Unified" is often overstated</li>
                    </ul>
                </div>
            </div>
            <p>Caliper side steps these challenges in several ways.</p> 
            <p><b>Caliper's Key Advantages</b></p>
            <div className='row'>
                <img src={pabstKeyAdvantages} />
                <ul>
                    <li><b>Rapid turnaround:</b> due to integration with platform APIs</li>
                    <li><b>Works with limited data:</b> since Caliper's digital focus enables usage of daily level observations.</li>
                    <li><b>Granular analysis:</b> from the utilization of unified pixel-tracked conversion data</li>
                    <li><b>Proper attribution of upper-funnel channels:</b> made possible by indirect effects modeling.</li>
                    <li><b>Transparency:</b> into measurement methodology and assumptions.</li>
                </ul>
                <p>While giving you a detailed analysis of your media value, optimal budget allocation recommendations, and forecasts.</p>
            </div>
            <div className='row'>
                <div className='col-lg-12'><img src={pabstDashboardImage} /></div> <br />
                <div className='col-lg-12'><span><b>What's illustrated above?</b></span></div>
                <ul>
                    <li>Effect of media channel/tactic vs investment</li>
                    <li>Response curves illustrating ad saturation of media channels/tactics</li>
                    <li>Decomposition of modeled seasonality and trend</li>
                    <li>Optimal budget allocation and forecasts</li>
                </ul>
            </div>
        </div>
        </>
    );
  }
}
export default LearnMore