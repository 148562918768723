import React, { Component } from "react";
import ApexChartsAPI, { exec } from 'apexcharts';
import ApexCharts from 'react-apexcharts';
import circle from '../images/circle.png';
import square from '../images/square.png'; 
import clonedeep from 'lodash/cloneDeep';
import moment from '@date-io/moment';

class LineChart extends React.Component {
    constructor(props) {
      super(props);

      var chartOptions = {}
      switch(this.props.chartType)
        {
            case "ResponseCurves":
                chartOptions = this.setResponseCurvesChartOptions()
                break;
            case "MarginalROAS":
                chartOptions = this.setChartOptions()
                break;
            case "MarginalROASOverall":
                chartOptions = this.setChartOptions()
                break;
            case "OverallResponseCurve":
                chartOptions = this.setChartOptions()
                break;
            case "TimeSeries":
                chartOptions = this.setTimeSeriesChartOptions();
                break;
        }

       this.state = {
       revenueSeries : [],
       costSeries: [],
       campaignGrouping: [],
       campaignGroupingIds: [],
       selectedBudgetPlanId: this.props.selectedBudgetPlanId,
       recommendedBudget: [],
       marginalROASSeries: [],
       isAnnotationToggled: true,
       chartOptions: chartOptions,
       marginalROASOverallSeries: [],
       overallSeries: [],
       timeSeries: [],
       timeSeriesKPI: [],
       chartOptions: chartOptions,
       timeSeriesParameters: {},
       };
    }

    componentDidMount = async () =>
    { 
        if ((this.props.chartType === "ResponseCurves" || this.props.chartType === "MarginalROAS") && this.props.viewType !== "Campaign")
        {
          this.fetchData(false);
        }

        if ((this.props.chartType === "MarginalROASOverall" || this.props.chartType === "OverallResponseCurve") && this.props.viewType !== "Campaign")
        {
          this.fetchMarginalData(false)
        }
    }

    static getDerivedStateFromProps(props, state)
    {
        return {selectedBudgetPlanId: props.selectedBudgetPlanId}
    }

    componentDidUpdate = async (prevProps, prevState) =>
    { 
        var recommendedBudget = this.props.recommendedBudget.map(x => x.rec_budget)
        if ((prevProps.selectedBudgetPlanId !== this.props.selectedBudgetPlanId && (this.props.chartType === "ResponseCurves" || this.props.chartType === "MarginalROAS" )))
        {
            this.fetchData(false);
            return;
        }

        if ((prevProps.selectedBudgetPlanId !== this.props.selectedBudgetPlanId && (this.props.chartType === "MarginalROASOverall" || this.props.chartType === "OverallResponseCurve")))
        {
            this.fetchMarginalData(false);
            return;
        }

        if (JSON.stringify(prevProps.selectedCampaignGroupings) !== JSON.stringify(this.props.selectedCampaignGroupings) && this.props.viewType === "Campaign")
        {
            var isOptimized = false
            if (!recommendedBudget.every(e => e === 0))
                isOptimized = true
            switch (this.props.chartType)
            {
                case "ResponseCurves":
                    this.fetchData(isOptimized)
                    break;
                case "MarginalROAS":
                    this.fetchData(isOptimized)
                    break;
            }
            return;
        }

        if (JSON.stringify(prevProps.recommendedBudget) !== JSON.stringify(this.props.recommendedBudget) && JSON.stringify(this.props.recommendedBudget) !== '[]')
        {
            var allAreZero = recommendedBudget.every(e => e === 0)
            if (!allAreZero)
            {
                switch (this.props.chartType)
                {
                    case "ResponseCurves":
                        this.fetchData(true)
                        break;
                    case "MarginalROAS":
                        this.fetchData(true)
                        break;
                    case "MarginalROASOverall":
                        this.fetchMarginalData(true)
                        break;
                    case "OverallResponseCurve":
                        this.fetchMarginalData(true)
                        break;
                }
            }
        }

        if (JSON.stringify(prevProps.recommendedBudget) !== JSON.stringify(this.props.recommendedBudget) && this.props.chartType === "TimeSeries")
        {
            var allAreZero = recommendedBudget.every(e => e === 0)
            if (!allAreZero)
            {
                this.props.fetchDataTimeSeriesLineChart()
                return;
            }
        }

        if (this.props.chartType === "TimeSeries")
        {
            if ((JSON.stringify(this.props.timeSeriesParams) !== JSON.stringify(this.state.timeSeriesParameters)) || JSON.stringify(this.state.timeSeriesKPI) !== JSON.stringify(this.props.timeSeriesKPI))
            {
              this.setTimeSeriesChartData();
            }
        }
    }

    fetchMarginalData = async (isOptimized) =>
    {
        var marginalOverallParams = await this.props.fetchMarginalOverallData(isOptimized)
        var costArray = marginalOverallParams[0]
        var kpiArray = marginalOverallParams[1]
        var marginalKpiArray = marginalOverallParams[2]
        if (this.props.chartType === "MarginalROASOverall")
        {
            this.setOverallMarginalROASChartData(costArray, marginalKpiArray, isOptimized);
        }

        if (this.props.chartType === "OverallResponseCurve")
        {
            this.setOverallResponseCurveChartData(costArray, kpiArray, isOptimized);
        }
    }

    setMarginalROASChartData = (json, isOptimized) =>
    {
        if (json.length !== 0)
        {
            var apiCostSeries = [];
            var apiCampaignGrouping = [];
            var marginalROASSeries = [];
            var data = json;

            for (var i = 0; i < data.length; i++)
            {
                apiCostSeries.push(data[i].cost)
                apiCampaignGrouping.push(data[i].campaign_group_name)
            }
            
            //get unique campaign groupings
            apiCampaignGrouping = apiCampaignGrouping.filter((v, i, a) => a.indexOf(v) === i);

            for (var x = 0; x < apiCampaignGrouping.length; x++)
            {

                var filteredData = data.filter(item => item.campaign_group_name === apiCampaignGrouping[x])
                var costDiff = filteredData[1].cost - filteredData[0].cost
                
                marginalROASSeries.push({
                    name: apiCampaignGrouping[x],
                    data: filteredData.map((item) => {
                        // Check if cost is within bounds
                        if (parseFloat(item.cost) >= (parseFloat(item.lower_quant_bounds_budget)+(costDiff)) && parseFloat(item.cost) <= (parseFloat(item.upper_quant_bounds_budget)+costDiff)) {
                        return item.marginal_kpi;
                        } else {
                        
                        return null;
                        }
                    })
                });
                
            }
            
            // sort the cost series for X axis and get unique values
            apiCostSeries.sort(function(a,b){return a-b;})
            apiCostSeries = apiCostSeries.filter((v, i, a) => a.indexOf(v) === i)
            //apiCostSeries = apiCostSeries.filter(x => {if(parseFloat(x) !== NaN){return x}})


            //apiCostSeries = this.multiplyDataSet(apiCostSeries)
            var options = this.setChartOptions(apiCostSeries, isOptimized)
       
            this.setState({
            campaignGrouping: apiCampaignGrouping,
            selectedBudgetPlanId: this.props.selectedBudgetPlanId,
            marginalROASSeries: marginalROASSeries,
            chartOptions: options
            }); 
        }
    }

    // simpleMovingAverage = (data, window) => {
    //     if (!data || data.length < window) {
    //       return [];
    //     }
    //     let index = window - 1;
    //     const length = data.length + 1;
    //     const simpleMovingAverages = [];
    //     while (++index < length) {
    //       const windowSlice = data.slice(index - window, index);
    //       const sum = windowSlice.reduce((prev, curr) => prev + curr, 0);
    //       simpleMovingAverages.push(sum / window);
    //     }
    //     return simpleMovingAverages;
    //   }

    setOverallMarginalROASChartData = (costArray, marginalKpiArray, isOptimized) =>
    {
        //var roas = []
        var marginalROASOverallSeries = []

        // for (var i = 1; i < kpiArray.length; i++)
        // {
        //     //change this later to be dynamic based on covnersion type
        //     if (this.props.converstionType !== 'Revenue')
        //         roas.push((costArray[i] - costArray[i-1]) / (kpiArray[i] - kpiArray[i-1]));
        //     else
        //         roas.push((kpiArray[i] - kpiArray[i-1]) / (costArray[i] - costArray[i-1]));
        // }
        //roas = this.simpleMovingAverage(roas, 3)
        marginalROASOverallSeries.push({name: "Marginal KPI Efficiency", data: marginalKpiArray})

        costArray.sort(function(a,b){return a-b;})
        costArray = costArray.filter((v, i, a) => a.indexOf(v) === i) 
        //costArray = this.multiplyDataSet(costArray)
        var options = this.setChartOptions(costArray, isOptimized)
           
        this.setState({
        marginalROASOverallSeries: marginalROASOverallSeries,
        chartOptions: options,
        }); 
    }

    setOverallResponseCurveChartData = (costArray, kpiArray, isOptimized) =>
    {
        var overallSeries = []
        overallSeries.push({name: "OverallResponseSeries", data: kpiArray}) //this.multiplyDataSet(kpiArray)})

        costArray.sort(function(a,b){return a-b;})
        costArray = costArray.filter((v, i, a) => a.indexOf(v) === i) 
        //costArray = this.multiplyDataSet(costArray)
        var options = this.setChartOptions(costArray, isOptimized)
           
        this.setState({
        overallSeries: overallSeries,
        chartOptions: options,
        }); 
    }

    setTimeSeriesChartData = () =>
    {
        var uniqueDates = this.props.timeSeriesDate.filter((v, i, a) => a.indexOf(v) === i);
        //Each index in this array represents a full week.
        var weeksArray = [];
        var timeSeriesWeeklyData = [];
        if (this.props.timeSeriesParams["timeSeriesSegment"] === "Monthly")
        {
            uniqueDates = uniqueDates.map((x) => {var date = x.split('-'); return date[1] + '-' + date[2] + '-' + date[0] })
            uniqueDates = uniqueDates.map((x) => {return new Date(x).toLocaleDateString('en-US', {month: 'short'})})
            uniqueDates = uniqueDates.filter((v, i, a) => a.indexOf(v) === i);
        }
        if (this.props.timeSeriesParams["timeSeriesSegment"].includes("Weekly"))
        {
            var weeklySegment = this.props.timeSeriesParams["timeSeriesSegment"].includes("(Sunday - Saturday)") ? 6 : 7
            timeSeriesWeeklyData = this.props.setTimeSeriesWeeklyData(weeklySegment, uniqueDates)
            weeksArray = timeSeriesWeeklyData[0];
            uniqueDates = timeSeriesWeeklyData[1];
        }
        var timeSeries = []
        var options = this.setTimeSeriesChartOptions(uniqueDates)
        timeSeries = this.props.timeSeriesParams["timeSeriesSegment"].includes("Weekly") ? this.createWeeklyTimeSeriesData(weeksArray) : this.createTimeSeriesData(uniqueDates, this.props.timeSeriesParams["timeSeriesSegment"])

        this.setState({
        timeSeries: timeSeries,
        timeSeriesKPI: this.props.timeSeriesKPI,
        chartOptions: options,
        previousData: this.props.data,
        timeSeriesParameters: clonedeep(this.props.timeSeriesParams)
        }); 
    } 

    createWeeklyTimeSeriesData = (weeksArray) =>
    {
        var timeSeriesCostData = [];
        var timeSeriesKPIData = [];
        var indexArray = [];
        var timeSeriesDate = this.props.timeSeriesDate;
        var timeSeriesCostArray = this.props.timeSeriesCost;
        var timeSeriesKPIArray = this.props.timeSeriesKPI;
        var timeSeriesCost = 0;
        var timeSeriesKPI = 0;

        if (this.props.timeSeriesParams["timeSeriesCampaignGrouping"] !== "All")
        {
            var timeSeriesCampaignGroupingData = this.props.timesSeriesCampaignFilter()
            timeSeriesDate = timeSeriesCampaignGroupingData[0];
            timeSeriesCostArray = timeSeriesCampaignGroupingData[1];
            timeSeriesKPIArray = timeSeriesCampaignGroupingData[2];
        }

        for (var x = 0; x < weeksArray.length; x++)
        {
            for (var y = 0; y < weeksArray[x].length; y++)
            {
                indexArray = timeSeriesDate.map((e, index) => e === weeksArray[x][y] ? index : '').filter(String);
                var timeSeriesCalculationResult = this.props.timeSeriesCalculation(indexArray, timeSeriesCostArray, timeSeriesKPIArray)
                timeSeriesCost = timeSeriesCost + timeSeriesCalculationResult[0]
                timeSeriesKPI = timeSeriesKPI + timeSeriesCalculationResult[1]
            }
            timeSeriesCostData.push(timeSeriesCost)
            timeSeriesKPIData.push(timeSeriesKPI)
            timeSeriesCost = 0;
            timeSeriesKPI = 0;
        }
        return [{name: "Spend", data:timeSeriesCostData}, {name: "KPI", data:timeSeriesKPIData}]
    }

    createTimeSeriesData = (uniqueDates, segment) =>
    {
        var timeSeriesCostData = [];
        var timeSeriesKPIData = [];
        var indexArray = [];
        var timeSeriesDate = this.props.timeSeriesDate;
        var timeSeriesCostArray = this.props.timeSeriesCost;
        var timeSeriesKPIArray = this.props.timeSeriesKPI;

        if (this.props.timeSeriesParams["timeSeriesCampaignGrouping"] !== "All")
        {
            var timeSeriesCampaignGroupingData = this.props.timesSeriesCampaignFilter()
            timeSeriesDate = timeSeriesCampaignGroupingData[0];
            timeSeriesCostArray = timeSeriesCampaignGroupingData[1];
            timeSeriesKPIArray = timeSeriesCampaignGroupingData[2];
        }
        
        for (var i = 0; i < uniqueDates.length; i++)
        {
            //get all indexs for the given dates
            switch (segment)
            {
                case "Daily":
                    indexArray = timeSeriesDate.map((e, index) => e === uniqueDates[i] ? index : '').filter(String);
                    break;
                case "Monthly":
                    indexArray = timeSeriesDate.map((e, index) => new Date(e).toLocaleDateString('default', {month: 'short'}) === uniqueDates[i] ? index : '').filter(String);
            }
        
            var timeSeriesCalculationResult = this.props.timeSeriesCalculation(indexArray, timeSeriesCostArray, timeSeriesKPIArray)
            timeSeriesCostData.push(timeSeriesCalculationResult[0])
            timeSeriesKPIData.push(timeSeriesCalculationResult[1])
        }

        return [{name: "Spend", data:timeSeriesCostData}, {name: "KPI", data:timeSeriesKPIData}]
    }

    setRecommendedBudget()
    {
        var selectedCampaignGroupIds = []
        var recommendedBudget = []
        if (this.props.viewType === "Campaign")
        {
            selectedCampaignGroupIds = this.props.selectedCampaignGroupings.map(x => x.value)
            var recommendedBudgetIds = this.props.recommendedBudget.map(x => x.id)

            for (var x = 0; x < selectedCampaignGroupIds.length; x++)
            {
                if (recommendedBudgetIds.includes(selectedCampaignGroupIds[x]))
                    recommendedBudget.push(this.props.recommendedBudget.find((e) => e.id === selectedCampaignGroupIds[x]).rec_budget)
            }
        }
        else
            recommendedBudget = this.props.recommendedBudget.map(x => x.rec_budget)

        return recommendedBudget
    }

    // gets the value from the series thats closest to the historical spend.
    getAnnotationCoordinates = (series, historicalSpend = 0) =>
    {
        if (series.length > 0 && historicalSpend > 0)
        {
            return series.reduce(function(prev, curr) {
                return (Math.abs(curr - historicalSpend) < Math.abs(prev -historicalSpend) ? curr : prev);
            });
        }

        return 0;
    }

    setBudgetAnnotation = (options, historicalSpend, annotationYAxis, annotationXAxis, annotationYAxisRecBudget, annotationXAxisRecBudget) =>
    {
        var recommendedBudget = this.setRecommendedBudget()
        
        var annotationList = {points: []}
        for (var i = 0; i < historicalSpend.length; i++)
        {
            annotationList.points.push({y: annotationYAxis[i], x: annotationXAxis[i], marker:{shape: "circle", size:5}, label: {text: Number(historicalSpend[i]).toFixed(2).toString()}})
        }
       
        for (var i = 0; i < recommendedBudget.length; i++)
        {
            var x = annotationXAxisRecBudget.length === 0 ? '0' : annotationXAxisRecBudget[i]
            var y = annotationYAxisRecBudget.length === 0 ? '0' : annotationYAxisRecBudget[i]
            annotationList.points.push({y: y, x: x, marker:{shape: "square", radius: 0, size:7}, label: {text: Number(recommendedBudget[i]).toFixed(2).toString()}})
        }
        options.annotations = annotationList;
        return options;
    }
    updateResponseCurveDataAndAnnotations = (json, chart, revenueSeries, costSeries, chartOptions, campaignGrouping, campaignGroupingIds, isAnnotationToggled) =>
    {
        var recommendedBudget = this.setRecommendedBudget()
        if (recommendedBudget.length > 0 && recommendedBudget.every(e => e !== 0))
        {
            if(JSON.stringify(recommendedBudget).includes('undefined'))
            {return;}
           // var multipliedDataSet = this.multiplyDataSetResponseCurve(chartOptions, revenueSeries, costSeries);
           //chartOptions = multipliedDataSet[2]
           // chartOptions.xaxis.categories = multipliedDataSet[0]
           chartOptions.xaxis.categories = costSeries

            // for (var i = 0; i < campaignGrouping.length; i++)
            // {
            //     var filteredJson = json.filter(item => item.campaign_group_name === campaignGrouping[i])
            //     chartOptions.annotations.points[i + (chartOptions.annotations.points.length / 2)].x = this.getAnnotationCoordinates(costSeries/*multipliedDataSet[0]*/, recommendedBudget[i])
            //     var YAxisAnnotationCoordinate = filteredJson.find(({cost}) => {return (parseFloat(cost) /** multipliedDataSet[3]*/).toFixed(2).toString() === chartOptions.annotations.points[i + (chartOptions.annotations.points.length / 2)].x});
            //     if (YAxisAnnotationCoordinate)
            //     {
            //         chartOptions.annotations.points[i + (chartOptions.annotations.points.length / 2)].y = YAxisAnnotationCoordinate.kpi_value /** multipliedDataSet[3]*/
            //     }
            //     chartOptions.annotations.points[i + (chartOptions.annotations.points.length / 2)].label.text = Number(recommendedBudget[i]).toFixed(0).toString()
            // }
            
            chart.updateOptions(chartOptions)
            //chart.updateSeries(multipliedDataSet[1])
            chart.updateSeries(revenueSeries)
            this.setState({
                chartOptions : chartOptions,
                revenueSeries: revenueSeries,//multipliedDataSet[1],
                costSeries : costSeries, //multipliedDataSet[0],
                campaignGrouping: campaignGrouping,
                campaignGroupingIds: campaignGroupingIds,
                isAnnotationToggled: isAnnotationToggled
            })        
        }
    }

    // multiplyDataSetResponseCurve = (chartOptions, revenueSeries, costSeries) =>
    // {
    //     var daysDiff = (Math.abs(new Date(this.props.selectedDateEnd) - new Date(this.props.selectedDateStart))) / (1000*60*60*24);
    //     costSeries = costSeries.map(x =>(parseFloat(x) * (daysDiff + 1)).toFixed(2))
    //     for (var i = 0; i < revenueSeries.length; i++)
    //     {
    //         revenueSeries[i].data = revenueSeries[i].data.map(x => (parseFloat(x) * (daysDiff + 1)).toFixed(2).toString())
    //     }
    //     for (i = 0; i <  chartOptions.annotations.points.length / 2; i++)
    //     {
    //         chartOptions.annotations.points[i].x = (parseFloat(chartOptions.annotations.points[i].x) * (daysDiff + 1)).toFixed(2).toString()
    //         chartOptions.annotations.points[i].y = (parseFloat(chartOptions.annotations.points[i].y) * (daysDiff + 1)).toFixed(2).toString()
    //         chartOptions.annotations.points[i].label.text = (parseFloat(chartOptions.annotations.points[i].label.text) * (daysDiff + 1)).toFixed(0).toString() 
    //     }

    //     return [costSeries, revenueSeries, chartOptions, daysDiff + 1]
    // }

    // multiplyDataSet = (series) =>
    // {
    //     if (this.props.selectedDateEnd !== null && this.props.selectedDateStart !== null)
    //     {
    //         var daysDiff = (Math.abs(new Date(this.props.selectedDateEnd) - new Date(this.props.selectedDateStart))) / (1000*60*60*24);
    //         series = series.map(x =>(parseFloat(x) * (daysDiff + 1)).toFixed(2))
    //     }
    //     return series;
    // }

    toggleAnnotations = () =>
    {
        var chart = ApexChartsAPI.getChartByID("ResponseCurves");
        var points = clonedeep(chart.annotations.pointsAnnotations.w.config.annotations.points);
        if (this.state.isAnnotationToggled)
        {
            points.map(val => val.label.style.cssClass = "hide-annotation");
            points.map(val => val.marker.size = 0);
            chart.annotations.pointsAnnotations.w.config.annotations.points = points;
            chart.updateOptions({});
            this.setState({isAnnotationToggled: false})
        }
        else
        {
            for (var i = 0; i < chart.series.w.globals.series.length; i++)
            {
                if (chart.series.w.globals.series[i].length > 0)
                {
                    points[i].label.style.cssClass = "show-annotation";
                    points[i].marker.size = 5;
                    points[i + (points.length / 2)].label.style.cssClass = "show-annotation";
                    points[i + (points.length / 2)].marker.size = 7;
                }
            }
            chart.annotations.pointsAnnotations.w.config.annotations.points = points;
            chart.updateOptions({});
            this.setState({isAnnotationToggled: true})
        }
    }

    fetchData = async (isOptimized) =>
    {
        //TODO
        //Change this to this.props.selectedGroupingsIds when passing to fetchResponseCurveData
        var json = await this.props.fetchResponseCurveData(this.props.selectedCampaignGroupings, this.props.selectedBudgetPlanId, isOptimized)
        if (this.props.chartType === "ResponseCurves")
        {
            var responseCurveSetupParams = await this.setResponseCurveData(json, isOptimized)
           
            if(isOptimized)
            {
                for (var i = 0; i < this.state.campaignGrouping.length; i++)
                {
                    var chart = ApexChartsAPI.getChartByID("ResponseCurves");
                    chart.showSeries(this.state.campaignGrouping[i])
                }
               await this.updateResponseCurveDataAndAnnotations(json, chart, responseCurveSetupParams[0], responseCurveSetupParams[5], responseCurveSetupParams[3], responseCurveSetupParams[1], responseCurveSetupParams[2], responseCurveSetupParams[4])
            }

            else
            {
                this.setState({
                    revenueSeries: responseCurveSetupParams[0],
                    campaignGrouping: responseCurveSetupParams[1],
                    campaignGroupingIds: responseCurveSetupParams[2],
                    chartOptions: responseCurveSetupParams[3],
                    isAnnotationToggled: responseCurveSetupParams[4],
                    costSeries: responseCurveSetupParams[5]
                }); 
            }
        }
            
        else
            this.setMarginalROASChartData(json, isOptimized);
    }

    setResponseCurveData = async (json, isOptimized) =>
    {
        if (json.length > 0)
        {
            var apiRevenueSeries = []
            var apiCostSeries = []
            var apiCampaignGrouping = []
            var apiCampaignAvgHistoricalSpend = []
            var apiAnnotationXAxis = []
            var apiAnnotationYAxis = []
            var apiAnnotiationXAxisRecBudget = []
            var apiAnnotiationYAxisRecBudget = []
            var apiCampaignGroupingIds = []

            for (var i = 0; i < json.length; i++)
            {
                apiCostSeries.push({cost:json[i].cost, campaignGroupingName: json[i].campaign_group_name})
                apiCampaignGrouping.push(json[i].campaign_group_name)
                apiCampaignGroupingIds.push(json[i].campaign_group_id)
                apiCampaignAvgHistoricalSpend.push(json[i].avg_hist_spend)
            }
            // sort the cost series for X axis
            apiCostSeries = apiCostSeries.sort(function(a,b){return a.cost-b.cost;})
            // get unique values for campaign avg_hist_spend
            apiCampaignAvgHistoricalSpend = apiCampaignAvgHistoricalSpend.filter((v, i, a) => a.indexOf(v) === i)
            // get unique values for campaign groupings
            apiCampaignGrouping = apiCampaignGrouping.filter((v, i, a) => a.indexOf(v) === i);
            apiCampaignGroupingIds = apiCampaignGroupingIds.filter((v, i, a) => a.indexOf(v) === i);
            var recommendedBudget = this.setRecommendedBudget()
            // for each campaign group, get the revenue data for that campaign group
            for (i = 0; i < apiCampaignGrouping.length; i++)
            {
                var filteredJson = json.filter(item => item.campaign_group_name === apiCampaignGrouping[i])
                var costDiff = filteredJson[1].cost - filteredJson[0].cost
                apiRevenueSeries.push({
                    name: apiCampaignGrouping[i],
                    data: filteredJson.map((item) => {
                      // Check if cost is within bounds
                      if (/*parseFloat(item.cost) >= (parseFloat(item.lower_quant_bounds_budget)-costDiff) && */ parseFloat(item.cost) <= (parseFloat(item.upper_quant_bounds_budget)+costDiff)) {
                        return item.kpi_value;
                      } else {
                        
                        return null;
                      }
                    })
                  });
                // get X and Y axis for annotating historical spend & recommended budget
                var costSeries = apiCostSeries.filter(x => {if(x.campaignGroupingName === apiCampaignGrouping[i]){return x}}).map(x => x.cost)
                apiAnnotationXAxis.push(this.getAnnotationCoordinates(costSeries, apiCampaignAvgHistoricalSpend[i]))
                apiAnnotationYAxis.push(filteredJson.find(({cost}) => cost === apiAnnotationXAxis[i]).kpi_value)
                if (recommendedBudget.every(e => e !== 0) && recommendedBudget.length > 0 && recommendedBudget.length === apiCampaignGrouping.length)
                {
                  apiAnnotiationXAxisRecBudget.push(this.getAnnotationCoordinates(costSeries, recommendedBudget[i]))
                  apiAnnotiationYAxisRecBudget.push(filteredJson.find(({cost}) => cost === apiAnnotiationXAxisRecBudget[i]).kpi_value)
                }
            }
            apiCostSeries = apiCostSeries.filter((v, i, a) => i === a.findIndex((t) => (t.cost === v.cost))).map(x => x.cost) 
            var options = this.setResponseCurvesChartOptions(apiCostSeries, isOptimized)
            options = this.setBudgetAnnotation(options, apiCampaignAvgHistoricalSpend, apiAnnotationYAxis, apiAnnotationXAxis, apiAnnotiationYAxisRecBudget, apiAnnotiationXAxisRecBudget)
            return [apiRevenueSeries, apiCampaignGrouping, apiCampaignGroupingIds, options, true, apiCostSeries]
        }      
    }

    setChartOptions = (seriesXAxis, isOptimized) =>
    {
        var titleText = ""
        var xAxisText = ""
        var yAxisText = ""
        var filename = ""
        switch (this.props.conversionType)
        {
            case 'Revenue':
                yAxisText = 'Marginal ROAS' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case 'Conversions':
                yAxisText = 'Marginal CPA' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case 'Reach':
                yAxisText = 'Marginal KPI / Spend' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case 'New Visitors':
                yAxisText = 'Marginal NV / Spend' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case "CPM":
                yAxisText = yAxisText = 'Marginal CPM' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case "Impressions":
                yAxisText = yAxisText = 'Marginal CPM' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case "Views":
                yAxisText = yAxisText = 'Marginal CPM' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case "ThruPlays":
                yAxisText = yAxisText = 'Marginal CPM' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case "Leads":
                yAxisText = yAxisText = 'Marginal CPL' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case "Purchases":
                yAxisText = yAxisText = 'Marginal Cost Per. Purchase' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case "Sales":
                yAxisText = yAxisText = 'Marginal Cost Per. Sale' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case "Landing Page Views":
                yAxisText = yAxisText = 'Marginal CPLPV' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case "Link Clicks":
                yAxisText = yAxisText = 'Marginal CPLC' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case "Free Trials":
                yAxisText = yAxisText = 'Marginal CPFT' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case "Installs":
                yAxisText = yAxisText = 'Marginal Cost Per. Install' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case "Referrals":
                yAxisText = yAxisText = 'Marginal Cost Per. Referrals' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case "Signups":
                yAxisText = yAxisText = 'Marginal Cost Per. Signup' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case "Clicks":
                yAxisText = yAxisText = 'Marginal CPC' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
            case "Bookings":
                yAxisText = yAxisText = 'Marginal Cost Per. Booking' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
        }
        switch(this.props.chartType)
        {
            case "MarginalROAS":
                titleText = 'Marginal KPI Efficiency by Grouping'
                xAxisText = 'Cost'
                filename = 'marginal_by_grouping_'
                break;
            case "MarginalROASOverall":
                titleText = 'Marginal KPI Efficiency Overall'
                xAxisText = 'Spend'
                filename = 'marginal_roas_overall_'
                break;
            case "OverallResponseCurve":
                filename = 'overall_'
                titleText = 'Overall Response Curve'
                xAxisText = 'Spend'
                yAxisText = this.props.conversionType +  (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
                break;
        } 
        var options = {
            chart: {
                height: 430,
                type: 'line',
                id: this.props.chartType,
                animations:{
                    enabled:false
                },
                dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
                },
                toolbar:{
                    show: true,
                    tools:
                    {
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true
                    },
                    export: {
                        csv: {
                            filename: filename + "_csv_" + Date.now(),
                          },
                        png: {
                           filename:filename + "_png_" + Date.now(),
                         },
                        svg: {
                            filename: filename + "_svg_" + Date.now(),
                          }
                     }
                },   
            },
            colors: ['#e63946', '#fcbf49', '#a8dadc', '#457b9d', '#1d3557'],
            dataLabels: {
                enabled: false,
            },
            
            title: {
                text: titleText,
                align: 'left',
                style: {
                    fontFamily:"'Inter', sans-serif",
                    fontSize:"18px",
                    color:"#3C3C3C",
                    fontWeight:"600",
                }
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
                },
            },
            markers: {
                size: 0
            },
            xaxis: {
                categories: seriesXAxis,
                title: {
                text: xAxisText
                },
                tickAmount: 10,
                labels:
                {
                   formatter: function(value){return Intl.NumberFormat('en-US', {style:'currency', currency: 'USD', minimumFractionDigits: 0, maximumFractionDigits:0}).format(value)}
                },
            },
            yaxis: {
                title: {
                text: yAxisText
                },
                decimalsInFloat: 2
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: false,
                offsetY: -25,
                offsetX: -5
            }
        }

        return options;
    }

    setTimeSeriesChartOptions = (seriesXAxis) =>
    {
        var yAxisText = this.props.conversionType
        if(this.props.selectedBudgetPlanId === 78)
        {
            yAxisText = 'Reach';
        }
        var options = {
            chart: {
                height: 430,
                type: 'line',
                id: this.props.chartType,
                animations:{
                    enabled:false
                },
                dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
                },
                toolbar:{
                    show: true,
                    tools:
                    {
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true
                    },
                    export: {
                        csv: {
                            filename: "TimeSeries_CSV_" + Date.now(),
                          },
                        png: {
                           filename: "TimeSeries_PNG_" + Date.now(),
                         },
                        svg: {
                            filename: "TimeSeries_SVG_" + Date.now(),
                          }
                     }
                },
            },
            colors: ['#457b9d','#e63946'],
            dataLabels: {
                enabled: false,
            },
            stroke: {
                curve: 'smooth', 
            },
            title: {
                text: "Time Series",
                align: 'left',
                style: {
                    fontFamily:"'Inter', sans-serif",
                    fontSize:"18px",
                    color:"#3C3C3C",
                    fontWeight:"600",
                }
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
                },
            },
            markers: {
                size: 0
            },
            xaxis: {
                categories: seriesXAxis,
                title: {
                text: "Date"
                },
                tickAmount: 15
            },
            yaxis: [{
                title: {
                text: 'Spend'
                },
                decimalsInFloat: 2
            },
            {
                opposite: true,
                title: {
                text: yAxisText
                },
                decimalsInFloat: 2
            }],
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: false,
                offsetY: -25,
                offsetX: -5
            }
        }

        return options;
    }

    setResponseCurvesChartOptions = (costSeries, isOptimized) =>
    {
        var self = this;
        var yAxisText = this.props.conversionType + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
        if(this.props.selectedBudgetPlanId === 78)
        {
            yAxisText = 'KPI / Spend' + (isOptimized ? "" : " (" + this.props.modelFrequency + ")")
        }
        var options = {
            chart: {
                height: 430,
                type: 'line',
                id: "ResponseCurves",
                animations:{
                    enabled:false
                },
                dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2,
                },
                toolbar:{
                    show: true,
                    tools:
                    {
                        selection: true,
                        zoom: true,
                        zoomin: true,
                        zoomout: true,
                        pan: true,
                        reset: true
                    },
                    export: {
                        csv: {
                            filename: "ResponseCurves_CSV_" + Date.now(),
                          },
                        png: {
                           filename: "ResponseCurves_PNG_" + Date.now(),
                         },
                        svg: {
                            filename: "ResponseCurves_SVG_" + Date.now(),
                          }
                     }
                },
                events:
                {
                    legendClick: function(chartContext, seriesIndex, config)
                    {
                       if (self.state.isAnnotationToggled)
                       {
                            if (config.config.annotations.points[seriesIndex].marker.size !== 0)
                            {
                                config.config.annotations.points[seriesIndex].marker.size = 0;
                                config.config.annotations.points[seriesIndex + (config.config.annotations.points.length / 2)].marker.size = 0;
                                var configItem = clonedeep(config.config.annotations.points[seriesIndex]);
                                var configItemTwo = clonedeep(config.config.annotations.points[seriesIndex + (config.config.annotations.points.length / 2)]);
                                configItem.label.style.cssClass = "hide-annotation"
                                configItemTwo.label.style.cssClass = "hide-annotation"
                                config.config.annotations.points[seriesIndex] = configItem
                                config.config.annotations.points[seriesIndex + (config.config.annotations.points.length / 2)] = configItemTwo
                            }
                            else
                            {
                                config.config.annotations.points[seriesIndex].marker.size = 5;
                                config.config.annotations.points[seriesIndex + (config.config.annotations.points.length / 2)].marker.size = 7;
                                var configItem = clonedeep(config.config.annotations.points[seriesIndex]);
                                var configItemTwo = clonedeep(config.config.annotations.points[seriesIndex + (config.config.annotations.points.length / 2)]);
                                configItem.label.style.cssClass = "show-annotation"
                                configItemTwo.label.style.cssClass = "show-annotation"
                                config.config.annotations.points[seriesIndex] = configItem
                                config.config.annotations.points[seriesIndex + (config.config.annotations.points.length / 2)] = configItemTwo
                            }
                       }
                    }
                }
            },
            colors: ['#e63946', '#fcbf49', '#a8dadc', '#457b9d', '#1d3557'],
            dataLabels: {
                enabled: false,
            },
            title: {
                text: 'Response Curves',
                align: 'left',
                style: {
                    fontFamily:"'Inter', sans-serif",
                    fontSize:"18px",
                    color:"#3C3C3C",
                    fontWeight:"600",
                }
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
                },
            },
            markers: {
                size: 0
            },
            xaxis: {
                type: 'category',
                //range: costSeries !== undefined ? [0, 200000] : [0,0],
                categories: costSeries,
                title: {
                text: 'Spend'
                },
                tickAmount: 10,
            },
            yaxis: {
                title: {
                    text: yAxisText
                },
            },
            legend: {
                position: 'top',
                horizontalAlign: 'right',
                floating: false,
                offsetY: -25,
                offsetX: -5
            },
        }

        return options;
    }

    render() {
        var series = [];
        switch(this.props.chartType)
        {
            case "ResponseCurves":
                series = this.state.revenueSeries;
                break;
            case "MarginalROAS":
                series = this.state.marginalROASSeries;
                break;
            case "MarginalROASOverall":
                series = this.state.marginalROASOverallSeries;
                break;
            case "TimeSeries":
                series = this.state.timeSeries;
                break;
            case "OverallResponseCurve":
                series = this.state.overallSeries;
                break;
        }
        var isResponseCurve = this.props.chartType === "ResponseCurves" ? true : false;
        var toggleAnnotationsText = this.state.isAnnotationToggled ? "Annotations: ON" : "Annotations: OFF"
        
        return (
        <div>
            <div id="chart">
            <ApexCharts id={this.props.chartType}  options={this.state.chartOptions} series={series} type="line" height="450px"/>
            </div>
                {isResponseCurve ? 
                (<>
                    <div className="response-curve-legend" style={{display: "inline"}}><img className="response-curve-legend-img-circle" src={circle}/> : Average Historical Spend</div> <div style={{display: "inline", marginLeft: "15px"}} className="response-curve-legend btn btn-primary" onClick={this.toggleAnnotations}>{toggleAnnotationsText}</div> <br />
                    <div className="response-curve-legend"><img className="response-curve-legend-img-square" src={square} /> : Recommended Spend</div>
                </>) : 
                ("") }
            </div>
        );
    }
  }

  export default LineChart