import React, { Component } from 'react';
import '../css/main.css';
import { BrowserRouter as Router, Route, Link, Redirect, Switch } from "react-router-dom";

export default class BudgetResultScenarioCellRenderer extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
          redirect: "",
          selectedBudgetOutput: null,
          selectedBudgetOutputId: null,
          accountId: null,
          converstionType: null,
          modelFrequency: null,
          accountName: null
        };
      }
  
    budgetResultScenarioClickEvent  = () =>
    {
      this.setState({
        redirect: '/budget-results-output',
        selectedBudgetOutput: this.props.value,
        selectedBudgetOutputId: this.props.data.id,
        accountId: this.props.colDef.accountId,
        conversionType: this.props.data.conversion_type,
        modelFrequency: this.props.data.model_frequency,
        accountName: this.props.data.name,
        campaignGroupingsAmount: this.props.data.campaign_group_count
        })
    }
    
    render() {
        if (this.state.redirect != "")
        {
          return <Redirect to={{
                  pathname: this.state.redirect,
                  state:{selectedBudgetOutput: this.state.selectedBudgetOutput, selectedBudgetOutputId: this.state.selectedBudgetOutputId, accountId: this.state.accountId, conversionType: this.state.conversionType, modelFrequency: this.state.modelFrequency, campaignGroupingsAmount: this.state.campaignGroupingsAmount, accountName: this.state.accountName}
                   }}></Redirect>
        }
        return <a onClick={this.budgetResultScenarioClickEvent} className="budget-results-output-link">{new Array(this.props.value)}</a>;
    }
}