import React, { Component } from 'react';
import '../css/main.css';
import InfoIcon from '@material-ui/icons/Flag';

export default class MinCellRenderer extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
        
        };
      }
    
    render() {
        var warningFlag = false
        if (this.props.data.warnings)
        {
            var warningText = ""
            if (this.props.data.warnings.length > 0)
            {
                for (var i = 0; i < this.props.data.warnings.length; i++)
                {
                    if (this.props.data.warnings[i].toLowerCase().includes("minimum") || this.props.data.warnings[i].toLowerCase().includes("min"))
                        warningText = warningText + this.props.data.warnings[i] + '\n'
                }
            }

            if (warningText !== "")
                warningFlag = true
            
        }
        return (
         <div>
            {warningFlag ? <InfoIcon style={{color:"orange"}}></InfoIcon> : "" }
            {new Array(this.props.value)}
        </div>
        )
    }
}