import React, { Component } from "react";
import fetchApi from '../FetchApi';
import ReactGA from "react-ga4";

ReactGA.initialize("G-YQ7D35VK7L")

export class AccountsDropdown extends Component {
    constructor(props) {
        super(props);

        this.state =
        {
            apiData: [{name: [], id: []}],
        }
    };

    componentDidMount = async () =>
    { 
        var apiData = await fetchApi("ACCOUNTS", null)
        ReactGA.event({category: apiData[0].name, action: "Budget Plan List"})
        this.setState({apiData: apiData})
        if (this.props.redirectAccountId !== 0) {
            // Find the index of the account with the matching ID in the apiData array
            const index = apiData.findIndex(item => item.id === this.props.redirectAccountId);
            if (index !== -1) {
                // Set the selected account ID in the state
                this.props.setAccountId(apiData[index].id);
            }
        }
        else
            this.props.setAccountId(apiData[0].id)
    }

    setAccountId = (e) =>
    {
       ReactGA.event({category: e.target.options[e.target.selectedIndex].text, action: "Budget Plan List"})
       this.props.setAccountId(e.target.value) 
    }

    render() {
        return (
            <div className="accounts-dropdown-container">
                <select id="AccountsDropdown" onChange={this.setAccountId}  className="accounts-dropdown" { ...(this.props.redirectAccountId !== 0 && { value: this.props.redirectAccountId }) }>
                    {this.state.apiData !== null ? this.state.apiData.map((item) =>(<option value={item.id}>{item.name}</option>) ) : "" }
                </select>
            </div>
        );
      }
}

export default AccountsDropdown
