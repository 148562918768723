import React, { Component } from 'react';
import { Container } from 'reactstrap';
import { NavMenu } from './NavMenu';
import {Login} from './Login';
import { Route } from 'react-router';
import Cookies from 'universal-cookie';
import { Redirect } from "react-router-dom";
import ServiceLandingPage from "../components/HomeContent/ServiceLandingPage"
import WithinChannel from '../pages/WithinChannel';
import CrossChannel from '../pages/CrossChannel';
import ContactUs from '../pages/ContactUs';
import LoginTemplate from '../pages/Login';
import ForgotPassword from '../pages/ForgotPassword'
import ResetPassword from '../pages/ResetPassword'
import {getAuthToken, getApiData} from '../FetchApi';
import laptop from '../images/iphone-login.png';

var cookies = new Cookies();
export class Layout extends Component {
  static displayName = Layout.name;
  
  constructor(props) {
    super(props);
    this.createLoginSession = this.createLoginSession.bind(this)
    this.state = 
    {
      pageType: "home",
      email: '',
      reset_token: ''
    }
  }

  componentDidMount = () =>
  {
    if(window.location.href.includes('reset-password'))
    {
      const queryParams = new URLSearchParams(window.location.search)
      const reset_token = queryParams.get('token');
      if(reset_token === null || reset_token === undefined || reset_token === '')
        window.location.href = "/"
      this.setState({reset_token: reset_token, pageType:"reset" })
    }

    else
    {
      if (cookies.get('remember') !== undefined)
      {
          this.createLoginSession(true, '', '', cookies.get('token'))
      }
    }
   
  }

  createLoginSession = (isAuthenticated, userId, accountPermissions, authToken) =>
  {
    if(isAuthenticated)
    {
      localStorage.setItem("UserID", userId)
      localStorage.setItem("AccountPermissions", accountPermissions)
      localStorage.setItem("AuthToken", authToken)
      this.forceUpdate()
    }
  }
  
  logout = () =>
  {
    var cookies = new Cookies();
    cookies.remove('remember');
    cookies.remove('token');
    localStorage.clear()
    this.setState({pageType:"home"})
  }

  setPage = (pageType) =>
  {
    this.setState({pageType:pageType})
  }

  formSubmit = async (username, password, rememberMe) =>
  { 
    var apiAuthToken = await getAuthToken("GET_AUTH_TOKEN", [username, password])
    if (apiAuthToken.token)
    {
        cookies.set('token', apiAuthToken.token, { path: '/', expires: new Date(Date.now()+2592000 * 30) });
        if (rememberMe === 'on')
        {
            cookies.set('remember', 'True', { path: '/', expires: new Date(Date.now()+2592000 * 30) });
        }
        this.createLoginSession(true, "", "", apiAuthToken.token)
    }

    else
    {
        this.createLoginSession(false, null, null, null)
    }
  }

  forgotPassword = (email) =>
  {
    var url = ""
    if (window.location.href.includes('localhost'))
      url = "http://localhost:3000/reset-password"
    else if (window.location.href.includes('staging'))
      url = "https://staging.mediacaliper.com/reset-password"
    else
      url = "https://mediacaliper.com/reset-password"

    getApiData("FORGOT_PASSWORD", [email, url])
    this.setState({pageType: "login"})
  }

  resetPassword = (password) =>
  {
    getApiData("RESET_PASSWORD", [this.state.reset_token, password])
    this.setState({pageType: "login"})
  }

  render () {
    if (localStorage.getItem("AuthToken") !== null)
    {
      return (
        <div>
          <NavMenu logout={this.logout} />
          <Container>
            {this.props.children}
          </Container>
        </div>
      );
    }
    else
    {
      return(
        <div>
        {
          {
            "home" : <ServiceLandingPage setPage={this.setPage}></ServiceLandingPage>,
            "within" : <WithinChannel setPage={this.setPage}></WithinChannel>,
            "cross" : <CrossChannel setPage={this.setPage}></CrossChannel>,
            "contact" : <ContactUs setPage={this.setPage}></ContactUs>,
            "login" : <LoginTemplate formSubmit={this.formSubmit} illustrationImageSrc={laptop} setPage={this.setPage}></LoginTemplate>,
            "forgot" : <ForgotPassword forgotPassword={this.forgotPassword} illustrationImageSrc={laptop} setPage={this.setPage}></ForgotPassword>,
            "reset" : <ResetPassword resetPassword={this.resetPassword} illustrationImageSrc={laptop} setPage={this.setPage}></ResetPassword>
          }[this.state.pageType]
        }
        </div>
      )
      //return(<><Login createLoginSession={this.createLoginSession}></Login><Redirect to="/" push={true}></Redirect></>)
    }
  }
}
