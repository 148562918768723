import React, { Component } from "react";
import ApexCharts from 'react-apexcharts';
import fetchApi from '../FetchApi';

export class GroupedBarChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
          spendShareSeries: [],
          effectShareSeries: [],
          campaignGrouping: [],
          roi: [],
          selectedBudgetPlanId: this.props.selectedBudgetPlanId
        }

        };

    componentDidMount = async () =>
    { 
      
      await this.fetchData();
      await this.adjustSvgHeight();
    }

    componentDidUpdate = async () =>
    {
      await this.adjustSvgHeight();
      if (this.props.selectedBudgetPlanId !== this.state.selectedBudgetPlanId)
      {
        await this.fetchData();
      }
    }

    fetchData = async () =>
    {
      var json = await fetchApi("SPEND_EFFECT_SHARE", [this.props.selectedBudgetPlanId])
      var apiSpendShareSeries = []
      var apiEffectShareSeries = []
      var apiCampaignGrouping = []
      var apiRoi = []
      for (var i = 0; i < json.length; i++)
      {
          apiSpendShareSeries.push(json[i].spend_share)
          apiEffectShareSeries.push(json[i].effect_share)
          apiCampaignGrouping.push(json[i].name)
          apiRoi.push(json[i].kpi_efficiency_value)
      }
    
      this.setState({
          spendShareSeries: apiSpendShareSeries,
          effectShareSeries: apiEffectShareSeries,
          campaignGrouping: apiCampaignGrouping,
          selectedBudgetPlanId : this.props.selectedBudgetPlanId,
          roi: apiRoi
      });
    }

    adjustSvgHeight = async() => {
      const svgElement = this.chart.chart.ctx.el.getElementsByTagName('svg')[0];
      svgElement.setAttribute('height', '800'); // Adjust the height value as needed
  }

    render() {
        var conversionType = ""
        switch (this.props.conversionType)
        {
          case "Revenue":
            conversionType = "ROAS";
            break;
          case "Reach":
            conversionType = "KPI / Spend";
            break;
          case "Conversions":
            conversionType = "CPA";
            break;
          case "New Visitors":
            conversionType = "NV / Spend";
            break;
          case "CPM":
            conversionType = "CPM";
            break;
          case "Impressions":
            conversionType = "CPM";
            break;
          case "Views":
            conversionType = "CPM";
            break;
          case "ThruPlays":
            conversionType = "CPM";
            break;
          case "Leads":
            conversionType = "CPL";
            break;
          case "Purchases":
            conversionType = "Cost Per. Purchase";
            break;
          case "Sales":
            conversionType = "Cost Per. Sale";
            break;
          case "Landing Page Views":
            conversionType = "CPLPV";
            break;
          case "Link Clicks":
            conversionType = "CPLC";
            break;
          case "Free Trials":
            conversionType = "CPFT";
            break;
          case "Installs":
            conversionType = "Cost Per. Install";
            break;
          case "Referrals":
            conversionType = "Cost Per. Referrals";
            break;
          case "Signups":
            conversionType = "Cost Per. Signup";
            break;
          case "Clicks":
            conversionType = "CPC";
            break;
          case "Bookings":
            conversionType = "Cost Per. Booking";
            break;

        }

        var series = [{name: this.props.conversionType + " %", type: 'column', data: this.state.effectShareSeries},{name: "Spend %", data: this.state.spendShareSeries, type: 'column'}, {name: conversionType , data: this.state.roi, type: 'line'}]
        var options = {
            chart: {
              type: 'line',
              offsetY: 10,
              
              toolbar:{
                show: true,
                tools:
                {
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false
                },
                export: {
                    csv: {
                        filename: "GroupedBarChart_CSV_" + Date.now(),
                      },
                    png: {
                       filename: "GroupedBarChart_PNG_" + Date.now(),
                     },
                    svg: {
                        filename: "GroupedBarChart_SVG_" + Date.now(),
                      }
                 }
            },
            },
            colors: ['#A8DADC', '#1D3557', '#ff0000'],
            dataLabels: {
              enabled: true,
              enabledOnSeries: [2]
            },
            
            stroke: {
              show: true,
              width: [0, 0, 2],
              curve: 'smooth',
            },
            yaxis: [
              {
                seriesName: this.props.conversionType + " %",
                title: {
                    text: "Percent"
                  },
              },
              {
                seriesName: "Spend %",
                show:false
              },
              {
                opposite:true,
                seriesName: conversionType,
                 title: {
                    text: conversionType,
                    style:{
                      color: "red"
                    }
                  }
                },
            ],
            tooltip: {
              shared: true,
              intersect: false
            },
            xaxis: {
              categories: this.state.campaignGrouping,
              labels: {
                rotate: -45,
                rotateAlways: true,
                hideOverlappingLabels: false,
                offsetX: 11,
                style: {
                  fontSize: '10px', // Adjust font size if needed
                 
                },
              }
            },
            legend: {
              position: 'top', // Move legend to the top of the chart
            },
           
          }
        return (
          <ApexCharts height={440} ref={chart => this.chart = chart} options={options} series={series} type="line" />
        );
      }
}

export default GroupedBarChart