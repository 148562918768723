import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { BudgetResults } from './components/BudgetResults';
import { BudgetResultsOutput } from './components/BudgetResultsOutput';
import { Documentation } from './components/Documentation';
import './custom.css'

export default class App extends Component {
  static displayName = App.name;

  constructor(props) {
      super(props);
      if (window.location.href.includes('localhost'))
        //localStorage.setItem("API_BASE_URL", "http://10.106.24.93:8107/")
        localStorage.setItem("API_BASE_URL", "http://127.0.0.1:8000/")
      else if (window.location.href.includes('staging'))
        localStorage.setItem("API_BASE_URL", "https://jsmdl9b407.execute-api.us-east-1.amazonaws.com/pre-prod/")
      else
        localStorage.setItem("API_BASE_URL", "https://3l7clrkrpl.execute-api.us-east-1.amazonaws.com/dev/")
    };

  render () {
    return (
      <Layout>
        <Route exact path='/' component={BudgetResults} />
        <Route exact path='/budget-results-output' component={BudgetResultsOutput} />
        <Route exact path='/docs' component={Documentation} />
      </Layout>
    );
  }
}
