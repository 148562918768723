// EnableCampaignGroup.jsx
import React, { useState, useEffect } from 'react';
import Switch from '@material-ui/core/Switch';

const EnableCampaignGroup = ({ data, api, rowIndex }) => {
  const [isEnabled, setIsEnabled] = useState(data.enabled);

  // Update local state when data.enabled changes
  useEffect(() => {
    setIsEnabled(data.enabled);
  }, [data.enabled]);

  const handleToggle = () => {
    setIsEnabled(!isEnabled);

    if (isEnabled) {
      api.DisableGroup(data.id, data.name, rowIndex, data.max_spend);
    } else {
      api.EnableGroup(data.id, data.name, rowIndex, data.max_spend);
    }
  };

  return (
    <Switch
      checked={isEnabled}
      onChange={handleToggle}
      color="primary"
    />
  );
};

export default EnableCampaignGroup;
